import { isLoggedIn } from '@moonpig/web-core-auth'
import React from 'react'
import { AppShell } from '@moonpig/web-core-app-shell'
import {
  CookieSerializeOptions,
  setCookie as setNextCookie,
} from '@moonpig/web-core-cookies'
import {
  buildExperimentsHeader,
  getExperiments,
} from '@moonpig/web-core-experiments'
import {
  GraphQLInitialState,
  getGraphQLInitialState,
} from '@moonpig/web-core-graphql'
import {
  BrowserErrorTracker,
  Metrics,
  logger,
  setLoggerConfig,
} from '@moonpig/web-core-monitoring'
import { redirect } from '@moonpig/web-core-routing'
import { Region } from '@moonpig/web-core-types'
import {
  getPlatform,
  isMobileUserAgent,
  isServer,
} from '@moonpig/web-core-utils'
import type { NextPageContext } from 'next'
import { AppContext as NextAppContext } from 'next/app'
import uuid from 'uuid/v4'
import { createStores } from '@moonpig/web-core-stores'
import { STORES } from '@moonpig/web-core-brand/config'
import { createAuthenticatedFetch } from '@moonpig/web-core-fetch'
import { createAppFlags } from '@moonpig/web-core-flags'
import {
  getTaggExperience,
  TAGG_EXPERIENCE_EXPERIMENT_NAME,
} from '@moonpig/web-core-marketing'
import { createEppoClient, setupEppo } from '@moonpig/web-core-eppo/server'
import { PageComponent, PageError, PageRedirect } from '../../types'
import { createLoggerConfig } from '../createLoggerConfig'
import { getABTestingServer } from './abTesting'
import {
  MAGIC_LINK_TOKEN_HEADER_NAME,
  STORE_COOKIE_MAX_AGE_30_DAYS,
  STORE_COOKIE_NAME,
} from './constants'
import { getGraphQLOptions } from './graphQLOptions'
import { getProxyApiStoreNamespace } from './proxyApiStoreNamespace'
import { getGraphQLContext } from './graphQLContext'
import { AppCoreProps, AppInitialProps } from './types'
import { AppContainer } from './AppContainer'
import { userIsBot } from './userIsBot'
import { setupSiteSpect } from './setupSiteSpect'
import { getRouteData } from './routeData'
import { first } from '../utils'

const stores = STORES
const eppoClientPromise = createEppoClient()
const { hasStore, defaultStore, getStoreById } = createStores(stores)

export const getInitialProps = async ({
  Component,
  params,
  ctx,
  environment,
  browserErrorTracker,
  getCookie,
  userAgent,
  metrics,
  notifications,
  flagSchema: appFlagSchema,
}: Pick<NextAppContext, 'Component' | 'ctx'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { [key: string]: any }
  environment: { localHostName: string }
  browserErrorTracker: BrowserErrorTracker
  getCookie: (name: string) => string | undefined
  userAgent: string
  metrics: Metrics
  notifications: React.ReactNode[]
  flagSchema: { [id: string]: { default: boolean } }
}): Promise<AppInitialProps> => {
  const OneMonthInSeconds = 60 * 60 * 24 * 30

  const setCookie = ((nextPageContext: NextPageContext) => {
    return (name: string, value: string, options?: CookieSerializeOptions) => {
      if (enableCaching) {
        metrics.count({
          metricName: 'caching-set-cookie',
          metricDimensions: { name },
        })
      }
      return setNextCookie(name, value, options, nextPageContext)
    }
  })(ctx)

  /* istanbul ignore next */
  const { asPath = '', pathname, query: ctxQuery, res } = ctx

  const [path, searchParams] = asPath.split(/\?/)

  const route = {
    asPath,
    path,
    pathname,
    query: ctxQuery,
  }

  const AppPageComponent = Component as PageComponent

  const pageRequiresLogin = AppPageComponent.requireLogin || false

  const isBot = userIsBot({
    userAgent,
    metrics,
  })

  const getCacheMaxAgeSeconds = () => {
    const cachingHeaderEnabled =
      ctx.req?.headers['mnpg-web-enable-caching'] === '1'

    const cacheMaxAge =
      cachingHeaderEnabled && AppPageComponent.cacheMaxAgeSeconds
        ? AppPageComponent.cacheMaxAgeSeconds
        : 0

    return cacheMaxAge
  }

  const cacheMaxAgeSeconds = getCacheMaxAgeSeconds()
  const enableCaching = cacheMaxAgeSeconds > 0

  const region: Region = params.region || defaultStore.id

  const store = getStoreById(params.region)

  const loggedIn = !enableCaching ? isLoggedIn(getCookie) : undefined

  if (pageRequiresLogin && params && params[MAGIC_LINK_TOKEN_HEADER_NAME]) {
    handleMagicLinkRedirect(
      ctx,
      path,
      searchParams,
      region,
      params[MAGIC_LINK_TOKEN_HEADER_NAME],
    )
  }

  if (pageRequiresLogin && loggedIn === false) {
    redirect({ res, path: `/${region}/account/login/`, returnUrl: asPath })
  }

  setStoreCookie({
    path,
    storeCookieValue: getCookie(STORE_COOKIE_NAME),
    setCookie,
  })

  const cookieSessionId = !enableCaching ? getCookie('mnpg_session_id') : null
  const sessionId = cookieSessionId || uuid()

  if (!enableCaching) {
    setCookie('mnpg_session_id', sessionId, {
      path: '/',
      maxAge: OneMonthInSeconds,
    })
  }

  const xForwardedFor = ctx?.req?.headers?.['x-forwarded-for'] || ''

  setLoggerConfig(
    createLoggerConfig(ctx, {
      x_forwarded_for: xForwardedFor,
      web_route: JSON.stringify(route),
      session_id: sessionId,
      store: store.id,
      ...(loggedIn !== undefined ? { is_logged_in: loggedIn } : {}),
    }),
  )

  const graphQLOptions = getGraphQLOptions({
    ctx,
    localHostName: environment.localHostName,
    sessionId,
    storeId: store.legacyId,
  })

  const enableServerAuth = !isBot && !enableCaching

  const authFetch = createAuthenticatedFetch(
    enableServerAuth,
    sessionId,
    metrics,
    ctx,
  )
  const graphQLContext = getGraphQLContext(
    graphQLOptions,
    authFetch.fetch,
    undefined,
  )

  const isBrowser = ctx.req === undefined

  let flagsHeader: string | undefined
  let routeDataToPersist: Record<string, string | undefined> | undefined

  if (isBrowser) {
    flagsHeader = (getRouteData() || {}).flagsHeader
  } /* isServer */ else {
    flagsHeader = first(ctx.req?.headers['mnpg-web-flags'] || '')
    routeDataToPersist = { flagsHeader }
  }

  const flags = createAppFlags({
    schema: appFlagSchema,
    header: flagsHeader,
    query: params.flag,
    getCookie: enableCaching ? null : getCookie,
  })

  const isUsingProxyApiStoreNamespace = getProxyApiStoreNamespace(ctx) !== null

  // Use control variant for E2E
  const forceControlVariant = isUsingProxyApiStoreNamespace

  const siteSpectCookies = {
    SSID: enableCaching ? undefined : getCookie('SSID'),
    SSRT: enableCaching ? undefined : getCookie('SSRT'),
    SSSC: enableCaching ? undefined : getCookie('SSSC'),
  }

  const forceDisableSiteSpect =
    isBot || enableCaching || flags['disable-sitespect']

  const mergeEppoExperiments = flags['merge-eppo-experiments']

  const { disableSiteSpect } = setupSiteSpect({
    forceDisable: forceDisableSiteSpect,
    getCookie,
    setCookie,
  })

  const abTestingServer = await getABTestingServer(
    siteSpectCookies,
    userAgent,
    params.variation || /* istanbul ignore next */ [],
    forceControlVariant,
    metrics,
    disableSiteSpect,
    setCookie,
  )

  const {
    getVariation,
    initialProps: abTestingProps,
    getAllCampaigns,
  } = abTestingServer

  const sitespectExperiments = await getExperiments(params, getVariation)

  const {
    eppoConfiguration,
    getExperimentVariant,
    getAllExperimentVariants: getAllEppoExperimentVariants,
  } = await setupEppo({
    sessionId,
    defaultAttributes: {
      storeId: store.id,
      loggedIn: loggedIn ?? false,
    },
    eppoClientPromise,
    overrideParams: params.variation,
  })

  const getAllExperimentVariants = (
    subjectAttributes?: Record<string, string | number | boolean>,
  ) => {
    if (mergeEppoExperiments) {
      const allEppoExperiments = getAllEppoExperimentVariants({
        subjectAttributes,
      })

      const stringifiedEppoExperiments = Object.entries(
        allEppoExperiments,
      ).reduce<Record<string, string>>((acc, [key, value]) => {
        acc[key] = value.toString()
        return acc
      }, {})

      return {
        ...stringifiedEppoExperiments,
        ...sitespectExperiments,
      }
    }
    return sitespectExperiments
  }

  graphQLOptions.headers['mnpg-experiments'] = buildExperimentsHeader(
    sitespectExperiments,
    mergeEppoExperiments ? getExperimentVariant : undefined,
  )

  const taggstarExperience = getTaggExperience(
    sitespectExperiments[TAGG_EXPERIENCE_EXPERIMENT_NAME],
  )

  const layoutType = AppPageComponent.layout || 'none'

  const { shouldHideFooter, hideSearchBar, hideGoogleOneTap } = AppPageComponent

  const shouldRenderAppBanner = Boolean(AppPageComponent.shouldRenderAppBanner)

  const enableThirdPartyScripts = flags['third-party-scripts']

  const { preview } = params

  const initialEnvironment = {
    ...environment,
    hostUrl:
      /* istanbul ignore next */
      process.env.ENABLE_PROXY_API || isUsingProxyApiStoreNamespace
        ? `http://${environment.localHostName}:${process.env.WEB_PORT}`
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          process.env.HOST_URL!,
  }

  const headerIsMobile = ctx.req?.headers['mnpg-web-is-mobile']
  const isMobile =
    headerIsMobile !== undefined
      ? headerIsMobile === '1'
      : isMobileUserAgent(userAgent)
  const platform = getPlatform(isMobile)

  graphQLOptions.headers['mnpg-is-mobile-browser'] = isMobile ? 'true' : 'false'

  const authEnabled = enableServerAuth || isBrowser

  const initialPropsPromise =
    AppPageComponent.getInitialProps &&
    AppPageComponent.getInitialProps({
      asPath,
      authEnabled,
      environment: initialEnvironment,
      fetch: authFetch.fetch,
      flags,
      getAllCampaigns,
      getCookie,
      getExperimentVariant,
      getAllExperimentVariants,
      graphQL: {
        query: graphQLContext.query,
      },
      logger,
      metrics,
      path,
      pathname: ctx.pathname,
      platform,
      preview,
      query: params,
      region,
      stores,
    })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const environmentName = process.env.MNPG_ENVIRONMENT_NAME!

  const [appShellProps, initialProps] = await Promise.all([
    AppShell.getInitialProps({
      environmentName,
      graphQL: {
        query: graphQLContext.query,
      },
      layoutType,
      experiments: sitespectExperiments,
      path,
      preview,
      query: params,
      region,
      shouldHideFooter,
      hideSearchBar,
      hideGoogleOneTap,
      shouldRenderAppBanner,
      eppoConfiguration,
    }),
    initialPropsPromise,
  ])

  const { pageOptions, pageError, pageRedirect, ...pageProps } =
    initialProps || /* istanbul ignore next */ {}

  const pageTracking =
    pageOptions && pageOptions.tracking
      ? /* istanbul ignore next */ pageOptions.tracking
      : { type: ctx.pathname.replace(/^\//, '') }

  const statusCode = getStatusCode(ctx, region, pageError, pageRedirect)

  if (statusCode === 301 || statusCode === 302) {
    return {
      statusCode,
      location: pageRedirect
        ? pageRedirect.location
        : /* istanbul ignore next */ '',
    }
  }

  const shouldRenderPageComponent =
    pageProps && !pageError && !pageRedirect && statusCode === 200

  const element = (
    <AppContainer
      appShellProps={appShellProps}
      authBaseUrl={graphQLOptions.authBaseUrl}
      browserErrorTracker={browserErrorTracker}
      enableThirdPartyScripts={enableThirdPartyScripts}
      experiments={sitespectExperiments}
      flags={flags}
      fetch={authFetch.fetch}
      isMobile={isMobile}
      notifications={notifications}
      pageRequiresLogin={pageRequiresLogin}
      pageTracking={pageTracking}
      region={region}
      sessionId={sessionId}
      loggedIn={loggedIn || false}
      taggstarExperience={taggstarExperience}
      eppoConfiguration={eppoConfiguration}
    >
      {shouldRenderPageComponent && <AppPageComponent {...pageProps} />}
    </AppContainer>
  )

  const graphQLInitialState: GraphQLInitialState = isServer
    ? await getGraphQLInitialState(element, graphQLContext)
    : { apolloInitialState: {} }

  const appProps: AppCoreProps = {
    abTestingProps,
    appShellProps,
    browserErrorTracker,
    enableServerAuth,
    enableThirdPartyScripts,
    experiments: sitespectExperiments,
    flags,
    graphQLInitialState,
    graphQLOptions,
    isMobile,
    notifications,
    pageRequiresLogin,
    pageTracking,
    preview,
    region,
    sessionId,
    loggedIn: loggedIn || false,
    routeDataToPersist,
    taggstarExperience,
    eppoConfiguration,
  }

  authFetch.writeCookies()

  if (statusCode === 200) {
    return {
      statusCode,
      pageProps,
      appProps,
      cacheMaxAgeSeconds,
    }
  }

  return {
    statusCode: statusCode as 404,
    appProps,
  }
}

const handleMagicLinkRedirect = (
  ctx: NextPageContext,
  returnPath: string,
  searchParams: string,
  region: string,
  token: string,
) => {
  const queryParams = new URLSearchParams({
    [MAGIC_LINK_TOKEN_HEADER_NAME]: token,
  })

  const urlSearchParams = new URLSearchParams(searchParams)
  urlSearchParams.delete(MAGIC_LINK_TOKEN_HEADER_NAME)

  const returnPathWithQuery = `${returnPath}${
    urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : ''
  }`

  redirect({
    res: ctx.res,
    path: `/${region}/account/login/`,
    returnUrl: returnPathWithQuery,
    queryString: queryParams.toString(),
  })
}

const setStoreCookie = ({
  path,
  storeCookieValue,
  setCookie,
}: {
  path: string
  storeCookieValue: string | undefined
  setCookie: (
    name: string,
    value: string,
    options?: CookieSerializeOptions,
    // eslint-disable-next-line @typescript-eslint/ban-types
  ) => {}
}) => {
  const currentStoreName = path.split('/')[1].toUpperCase()

  if (currentStoreName && currentStoreName !== storeCookieValue) {
    setCookie(STORE_COOKIE_NAME, currentStoreName, {
      maxAge: STORE_COOKIE_MAX_AGE_30_DAYS,
      path: '/',
    })
  }
}

const getStatusCode = (
  ctx: NextPageContext,
  storeId: string,
  pageError?: PageError,
  pageRedirect?: PageRedirect,
): number => {
  /* istanbul ignore if */
  if (!hasStore(storeId)) return 404
  if (pageError && pageError.statusCode) return pageError.statusCode
  if (pageRedirect && pageRedirect.statusCode) return pageRedirect.statusCode
  /* istanbul ignore if */
  if (ctx.res && ctx.res.statusCode) return ctx.res.statusCode
  /* istanbul ignore if */
  if (ctx.err && /* istanbul ignore next */ ctx.err.statusCode)
    return ctx.err.statusCode
  return 200
}
