import { z } from 'zod'
import {
  eventDataSchema,
  resultsDataSchema,
  zodNumber,
  zodResultsDataSuggestionTypeEnum,
  zodSearchSuggestionEventLabelString,
} from '../common.schema'

export const SearchSuggestionClickSchema = z.object({
  event: z.literal('search_suggestion_click'),

  event_data: eventDataSchema.extend({
    label: zodSearchSuggestionEventLabelString,
  }),
  results_data: resultsDataSchema.extend({
    index: zodNumber.int().positive({ message: 'index should start from 1' }),
    suggestion_type: zodResultsDataSuggestionTypeEnum
      .or(z.enum(['search', 'reminders']))
      .optional(),
  }),
})

export type SearchSuggestionClickEvent = z.infer<
  typeof SearchSuggestionClickSchema
>
