import { z } from 'zod'
import { ecommerceItemSchema } from '../common.schema'

export const refinedEcommerceItemSchema = ecommerceItemSchema.superRefine(
  (val, ctx) => {
    if (typeof val.index !== 'number') {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        expected: 'number',
        received: typeof val.index,
        message: '`index` is required in ecommerce item for this event',
      })
    } else if (val.index <= 0) {
      ctx.addIssue({
        type: 'number',
        code: z.ZodIssueCode.too_small,
        minimum: 1,
        inclusive: false,
        message: '`item.index` should start from 1 for this event',
      })
    }

    if (typeof val.item_category !== 'string') {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        expected: 'string',
        received: typeof val.item_category,
        message: '`item_category` is required in ecommerce item for this event',
      })
    }

    if (typeof val.item_category2 !== 'string') {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        expected: 'string',
        received: typeof val.item_category2,
        message:
          '`item_category2` is required in ecommerce item for this event',
      })
    }

    if (typeof val.price !== 'number') {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        expected: 'number',
        received: typeof val.price,
        message: '`price` is required in ecommerce item for this event',
      })
    }

    if (typeof val.quantity !== 'number') {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        expected: 'number',
        received: typeof val.quantity,
        message: '`quantity` is required in ecommerce item for this event',
      })
    }
  },
)
