import { EppoClient, VariationType } from '@eppo/js-client-sdk-common'
import { DefaultSubjectAttributes } from '../../shared/DefaultSubjectAttributes'

type StringVariant = {
  type: VariationType.STRING
  fallback: string
}

type NumberVariant = {
  type: VariationType.INTEGER | VariationType.NUMERIC
  fallback: number
}

type BooleanVariant = {
  type: VariationType.BOOLEAN
  fallback: boolean
}

type JSONVariant = {
  type: VariationType.JSON
  fallback: object
}

type Variant = StringVariant | BooleanVariant | NumberVariant | JSONVariant

type Attributes = {
  [key: string]: string | number | boolean
}

export type GetExperimentVariantParams = Variant & {
  subjectAttributes?: Attributes
}

export type GetExperimentVariant = (
  experimentKey: string,
  params: GetExperimentVariantParams,
) => string | number | object | boolean

export const setupGetExperimentVariant = ({
  client,
  subjectKey,
  defaultAttributes,
  overrides,
}: {
  client: EppoClient | null
  subjectKey: string
  defaultAttributes: DefaultSubjectAttributes
  overrides: { [key: string]: string }
}) => {
  const getExperimentVariant: GetExperimentVariant = (
    experimentKey,
    { type, fallback, subjectAttributes },
  ) => {
    if (!client) return fallback

    const attributes = {
      ...defaultAttributes,
      ...subjectAttributes,
    }

    const shouldOverride = Object.keys(overrides).includes(experimentKey)

    switch (type) {
      case VariationType.STRING:
        if (shouldOverride) {
          return overrides[experimentKey]
        }
        return client.getStringAssignment(
          experimentKey,
          subjectKey,
          attributes,
          fallback,
        )
      case VariationType.BOOLEAN:
        if (shouldOverride) {
          return overrides[experimentKey] === 'true'
        }
        return client.getBooleanAssignment(
          experimentKey,
          subjectKey,
          attributes,
          fallback,
        )
      case VariationType.INTEGER:
        if (shouldOverride) {
          return Number(overrides[experimentKey])
        }
        return client.getIntegerAssignment(
          experimentKey,
          subjectKey,
          attributes,
          fallback,
        )
      case VariationType.NUMERIC:
        if (shouldOverride) {
          return Number(overrides[experimentKey])
        }
        return client.getNumericAssignment(
          experimentKey,
          subjectKey,
          attributes,
          fallback,
        )
      case VariationType.JSON:
        if (shouldOverride) {
          return JSON.parse(overrides[experimentKey])
        }
        return client.getJSONAssignment(
          experimentKey,
          subjectKey,
          attributes,
          fallback,
        )
    }
  }
  return getExperimentVariant
}
