import React, { FC } from 'react'
import { useInView } from 'react-intersection-observer'
import { Heading, Text, Container, Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { useStoreId } from '@moonpig/web-core-stores'
import { Carousel } from '@moonpig/web-shared-components'
import { useBundleGifts } from '../useBundleGifts'
import { BundleTile } from '../BundleTile/BundleTile'
import { createBundlesViewItemListGA4Event } from './createBundlesViewItemListEvent'
import { useFindLocaleText } from '../../../text-localisation'

type BundlesModuleProps = {
  trackingDataProps: {
    pageLocation: string
    moduleIndex: number | string
    modulesLength: number | string
  }
}

const StyledSection = styled.section`
  ${s({
    pt: 10,
    bgcolor: 'colorBackground06',
  })}
`

const StyledBundleItemContainer = styled(Box)`
  height: 100%;
  ${s({
    pt: 8,
    pb: 12,
    px: 4,
  })}
`

export const Bundles: FC<BundlesModuleProps> = ({ trackingDataProps }) => {
  const gifts = useBundleGifts()
  const { trackGAEventOnce } = useTrackGAEventOnce()
  const localiseText = useFindLocaleText()
  const carouselHeading = localiseText('find.bundle.carousel.heading')
  const carouselDescription = localiseText('find.bundle.carousel.description')
  const storeId = useStoreId()
  const [productListRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  if (!gifts.length) {
    return null
  }

  if (inView && trackingDataProps) {
    trackGAEventOnce(
      createBundlesViewItemListGA4Event({
        pageLocation: trackingDataProps.pageLocation,
        carouselHeading,
        carouselPosition: trackingDataProps.moduleIndex,
        totalNumberOfCarousels: trackingDataProps.modulesLength,
        gifts,
        brand: storeId === 'nl' ? 'greetz' : 'moonpig',
      }),
    )
  }

  return (
    <StyledSection data-testid="module-bundles" ref={productListRef}>
      <Container limitWidth>
        <Box textAlign={{ xs: 'left', md: 'center' }}>
          <Heading
            level="h2"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
          >
            {carouselHeading}
          </Heading>
          <Text>{carouselDescription}</Text>
        </Box>

        <Box mx={{ md: -6 }}>
          <Carousel itemsGap={{ xs: 0 }}>
            {gifts.map((gift, index) => {
              const isFirst = index === 0
              const isLast = index === gifts.length - 1
              return (
                <StyledBundleItemContainer
                  data-testid="bundle-item-container"
                  key={gift.id}
                  ml={isFirst ? { xs: 4, md: 0 } : 0}
                  mr={isLast ? { xs: 4, md: 0 } : 0}
                >
                  <BundleTile
                    gift={gift}
                    showDiscountedPrice
                    trackingData={
                      trackingDataProps && {
                        pageLocation: trackingDataProps.pageLocation,
                        carousel: {
                          heading: carouselHeading,
                          length: trackingDataProps.modulesLength,
                          position: trackingDataProps.moduleIndex,
                        },
                        bundle: {
                          length: gifts.length,
                          position: index,
                        },
                      }
                    }
                  />
                </StyledBundleItemContainer>
              )
            })}
          </Carousel>
        </Box>
      </Container>
    </StyledSection>
  )
}
