import React, { FC, Children, PropsWithChildren } from 'react'
import {
  CarouselState,
  CarouselRoot,
  CarouselControls,
  UnstyledCarouselScrollArea,
  CarouselPreviousButton,
  CarouselNextButton,
  CarouselItem,
  useCarousel,
} from '@moonpig/launchpad-components'
import { styled, breakpointUp, breakpointDown } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

export type Props = {
  hideScrollButtons?: boolean
  itemsGap?: { xs?: number; md?: number }
  noSpacing?: boolean
  onScroll?: () => void
  ariaLabel?: string
  centerSlides?: boolean
  centerNonScrollableItems?: boolean
  skipLinkHref?: string
}

const StyledCarouselItem = styled(CarouselItem)`
  &:first-child {
    ${s({ ml: { xs: 5, md: 0 } })}
  }

  &:last-child {
    ${s({ mr: { xs: 5, md: 0 } })}
  }
`

const StyledCarouselScrollArea = styled(UnstyledCarouselScrollArea)<{
  centerSlides?: boolean
  gap?: { xs?: number; md?: number }
}>`
  ${({ gap }) =>
    s({
      gap: gap || { xs: 12, md: 16 },
    })};

  ${breakpointUp('md')} {
    justify-content: ${({ centerSlides }) =>
      centerSlides ? 'center' : 'initial'};
  }
`

const StyledPreviousButton = styled(CarouselPreviousButton)`
  ${breakpointDown('md')} {
    display: none;
  }
`

const StyledNextButton = styled(CarouselNextButton)`
  ${breakpointDown('md')} {
    display: none;
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledCarouselRoot = styled(({ noSpacing, ...props }) => (
  <CarouselRoot {...props} />
))<{ noSpacing: boolean }>`
  ${({ noSpacing }) =>
    s({
      mx: noSpacing ? 0 : -6,
      px: noSpacing ? 0 : 6,
    })};

  ${breakpointDown('md')} {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
`

const CarouselDOMWrapper: FC<Props> = ({
  hideScrollButtons,
  itemsGap,
  children,
  noSpacing = false,
  onScroll,
  ariaLabel,
  centerSlides = false,
  centerNonScrollableItems = false,
  skipLinkHref,
}) => {
  const { scrollLeft, maxScrollLeft } = useCarousel(state => ({
    scrollLeft: state.scrollLeft,
    maxScrollLeft: state.maxScrollLeft,
  }))
  const itemsCentered =
    hideScrollButtons ||
    centerSlides ||
    (centerNonScrollableItems &&
      scrollLeft === 0 &&
      maxScrollLeft === scrollLeft)

  return (
    <StyledCarouselRoot
      noSpacing={noSpacing}
      aria-label={ariaLabel}
      skipLinkHref={skipLinkHref}
    >
      <CarouselControls>
        {!hideScrollButtons && <StyledPreviousButton />}
        {!hideScrollButtons && <StyledNextButton />}
      </CarouselControls>
      <StyledCarouselScrollArea
        centerSlides={itemsCentered}
        data-testid="lp-components-carousel-scroll-area"
        gap={itemsGap}
        onScroll={onScroll}
      >
        {Children.map(children, (item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <StyledCarouselItem tabIndex={undefined} key={index}>
            {item}
          </StyledCarouselItem>
        ))}
      </StyledCarouselScrollArea>
    </StyledCarouselRoot>
  )
}

export const Carousel: FC<PropsWithChildren<Props>> = ({
  children,
  ...rest
}) => {
  return (
    <CarouselState>
      <CarouselDOMWrapper {...rest}>{children}</CarouselDOMWrapper>
    </CarouselState>
  )
}
