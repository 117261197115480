import { z } from 'zod'
import {
  errorDataSchema,
  eventDataSchema,
  userDataSchema,
  zodAddressCountString,
  zodBoolean,
} from '../../common.schema'

export const RecipientErrorSchema = z.object({
  event: z.literal('recipient_error'),

  user_data: userDataSchema.extend({
    address_count: zodAddressCountString,
    is_logged_in: zodBoolean.optional(),
  }),
  event_data: eventDataSchema,
  error_data: errorDataSchema,
})

export type RecipientErrorEvent = z.infer<typeof RecipientErrorSchema>
