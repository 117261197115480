import React, { FC } from 'react'
import { LinkAsProvider } from '@moonpig/launchpad-components'
import { theme } from '@moonpig/launchpad-theme'
import { AppShellProps, AppShell } from '@moonpig/web-core-app-shell'
import { FlagsProvider } from '@moonpig/web-core-flags'
import {
  LoggedInProvider,
  LoginModalProvider,
  AuthProvider,
  GoogleOneTapProvider,
} from '@moonpig/web-core-auth'
import { FetchProvider, Fetch } from '@moonpig/web-core-fetch'
import {
  PageRequiresLoginProvider,
  RemindersModalProvider,
  NotificationsProvider,
  PlatformProvider,
} from '@moonpig/web-core-context'
import { Experiments, ExperimentsProvider } from '@moonpig/web-core-experiments'
import {
  BrowserErrorTracker,
  BrowserErrorTrackerProvider,
} from '@moonpig/web-core-monitoring'
import { LocaleTextProvider as LaunchpadLocaleTextProvider } from '@moonpig/launchpad-localisation'
import { DefaultContentSecurityPolicy } from '@moonpig/web-core-security'
import { useLocale, StoreProvider } from '@moonpig/web-core-stores'
import { EppoContext, createEppoClient } from '@moonpig/web-core-eppo/browser'
import { EppoConfiguration } from '@moonpig/web-core-eppo/types'
import { Region } from '@moonpig/web-core-types'
import { SessionIdProvider } from '@moonpig/web-core-utils'
import { ThemeProvider } from '@moonpig/launchpad-utils'
import { SetupGTM } from '@moonpig/web-core-analytics'
import { Link } from '@moonpig/web-core-link'
import { TaggstarProvider } from '@moonpig/web-core-marketing'
import { CreateSailthruCookie } from './CreateSailthruCookie'
import { LoginModal } from './LoginModal'
import { SendGAUserData } from './SendGAUserData'
import { useLoginGuard } from '../../hooks'
import { SendGALoginEvent } from './SendGALoginEvent'

const DynamicallySetupGTM: FC<{
  pageTracking: { type: string; disableDefaultPageTracking?: boolean }
  enableThirdPartyScripts?: boolean
}> = ({ pageTracking, enableThirdPartyScripts }) => {
  return (
    <SetupGTM
      pageTracking={pageTracking}
      enableThirdPartyScripts={enableThirdPartyScripts}
    />
  )
}

type Flags = { [id: string]: boolean }

export const AppContainer: FC<
  React.PropsWithChildren<{
    appShellProps: AppShellProps
    authBaseUrl?: string
    browserErrorTracker: BrowserErrorTracker
    enableThirdPartyScripts?: boolean
    experiments: Experiments
    fetch: Fetch
    flags: Flags
    isMobile: boolean
    notifications: React.ReactNode[]
    pageRequiresLogin: boolean
    pageTracking: { type: string; disableDefaultPageTracking?: boolean }
    region: Region
    sessionId: string
    loggedIn: boolean
    taggstarExperience: string
    eppoConfiguration: EppoConfiguration
  }>
> = ({
  appShellProps,
  authBaseUrl,
  browserErrorTracker,
  children,
  enableThirdPartyScripts,
  experiments,
  fetch,
  flags,
  isMobile,
  notifications,
  pageRequiresLogin,
  pageTracking,
  region,
  sessionId,
  loggedIn,
  taggstarExperience,
  eppoConfiguration,
}) => {
  const eppoClient = createEppoClient({
    flagsConfiguration: eppoConfiguration,
  })

  const { isCustomerLoggedIn } = useLoginGuard(loggedIn, pageRequiresLogin)
  const { language } = useLocale()
  const storeId = region

  return (
    <EppoContext.Provider value={eppoClient}>
      <StoreProvider storeId={storeId}>
        <FetchProvider fetch={fetch}>
          <FlagsProvider flags={flags}>
            <ExperimentsProvider experiments={experiments}>
              <PageRequiresLoginProvider value={pageRequiresLogin}>
                <LoggedInProvider value={{ loggedIn: isCustomerLoggedIn }}>
                  <RemindersModalProvider value={{ show: false }}>
                    <NotificationsProvider value={{ notifications }}>
                      <SessionIdProvider sessionId={sessionId}>
                        <BrowserErrorTrackerProvider
                          browserErrorTracker={browserErrorTracker}
                        >
                          <LoginModalProvider>
                            <AuthProvider authBaseUrl={authBaseUrl}>
                              <GoogleOneTapProvider>
                                <PlatformProvider isMobile={isMobile}>
                                  <TaggstarProvider
                                    currentExperience={taggstarExperience}
                                  >
                                    <ThemeProvider theme={theme}>
                                      <LaunchpadLocaleTextProvider
                                        locale={language}
                                      >
                                        <LinkAsProvider
                                          linkAs={
                                            Link as React.ComponentProps<
                                              typeof LinkAsProvider
                                            >['linkAs']
                                          }
                                        >
                                          <AppShell {...appShellProps}>
                                            <DynamicallySetupGTM
                                              enableThirdPartyScripts={
                                                enableThirdPartyScripts
                                              }
                                              pageTracking={pageTracking}
                                            />
                                            <SendGALoginEvent />
                                            <SendGAUserData />
                                            <CreateSailthruCookie />
                                            <DefaultContentSecurityPolicy />
                                            <LoginModal />
                                            {children}
                                          </AppShell>
                                        </LinkAsProvider>
                                      </LaunchpadLocaleTextProvider>
                                    </ThemeProvider>
                                  </TaggstarProvider>
                                </PlatformProvider>
                              </GoogleOneTapProvider>
                            </AuthProvider>
                          </LoginModalProvider>
                        </BrowserErrorTrackerProvider>
                      </SessionIdProvider>
                    </NotificationsProvider>
                  </RemindersModalProvider>
                </LoggedInProvider>
              </PageRequiresLoginProvider>
            </ExperimentsProvider>
          </FlagsProvider>
        </FetchProvider>
      </StoreProvider>
    </EppoContext.Provider>
  )
}
