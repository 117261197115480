import { useContext, useMemo } from 'react'
import { useSessionId } from '@moonpig/web-core-utils'
import { VariationType } from '@eppo/js-client-sdk-common'

import { useRouter } from '@moonpig/web-core-routing'
import { logger } from '@moonpig/web-core-monitoring'
import { EppoContext } from '../EppoContext/EppoContext'
import { useDefaultSubjectAttributes } from '../useDefaultSubjectAttributes/useDefaultSubjectAttributes'
import { parse } from '../../utils/parse-params/parse-params'

type StringVariant = {
  type: VariationType.STRING
  fallback: string
}

type NumberVariant = {
  type: VariationType.INTEGER | VariationType.NUMERIC
  fallback: number
}

type BooleanVariant = {
  type: VariationType.BOOLEAN
  fallback: boolean
}

type JSONVariant = {
  type: VariationType.JSON
  fallback: object
}

type Variant = StringVariant | BooleanVariant | NumberVariant | JSONVariant

type Attributes = {
  [key: string]: string | number | boolean
}

export type UseEppoExperimentParams = {
  subjectKey?: string
  subjectAttributes?: Attributes
} & Variant

export function useEppoExperiment(
  experimentKey: string,
  { fallback, subjectAttributes, type }: UseEppoExperimentParams,
) {
  const defaultAttributes = useDefaultSubjectAttributes()
  const router = useRouter()

  const route = router.getCurrentRoute()

  const client = useContext(EppoContext)
  const { sessionId } = useSessionId()

  const assignedVariation = useMemo(() => {
    const attributes = {
      ...defaultAttributes,
      ...subjectAttributes,
      shouldLog: true,
    }
    if (!client) {
      logger.fixToday('useEppoExperiment: Eppo client is not defined')
      return fallback
    }

    const overrides = parse((route.params as { variation: string[] }).variation)

    const shouldOverride = Object.keys(overrides).includes(experimentKey)

    switch (type) {
      case VariationType.STRING:
        if (shouldOverride) {
          return overrides[experimentKey]
        }
        return client.getStringAssignment(
          experimentKey,
          sessionId,
          attributes,
          fallback,
        )
      case VariationType.BOOLEAN:
        if (shouldOverride) {
          return overrides[experimentKey] === 'true'
        }
        return client.getBooleanAssignment(
          experimentKey,
          sessionId,
          attributes,
          fallback,
        )
      case VariationType.INTEGER:
        if (shouldOverride) {
          return Number(overrides[experimentKey])
        }
        return client.getIntegerAssignment(
          experimentKey,
          sessionId,
          attributes,
          fallback,
        )
      case VariationType.NUMERIC:
        if (shouldOverride) {
          return Number(overrides[experimentKey])
        }
        return client.getNumericAssignment(
          experimentKey,
          sessionId,
          attributes,
          fallback,
        )
      case VariationType.JSON:
        if (shouldOverride) {
          return JSON.parse(overrides[experimentKey])
        }
        return client.getJSONAssignment(
          experimentKey,
          sessionId,
          attributes,
          fallback,
        )
    }
  }, [
    client,
    defaultAttributes,
    experimentKey,
    fallback,
    route.params,
    sessionId,
    subjectAttributes,
    type,
  ])

  return assignedVariation
}
