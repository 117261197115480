import React, { FC, useEffect, useReducer } from 'react'
import { useInView } from 'react-intersection-observer'
import { trySetLocalStorage } from '@moonpig/web-shared-utils'
import { ModuleEmailCaptureFragment } from './__generated__/fragment'
import { ModuleSection } from '../../components/ModuleContainer'
import { EmailForm, ThankYou } from './components'
import {
  initialImpression,
  privacyNoticeClickEventData,
  track,
} from './tracking/events'
import { useActions } from './store/actions'
import { initialState, reducer } from './store/reducer'
import { HAS_DISMISSED_NEWSLETTER_SUBSCRIPTION_MODAL_KEY } from './store/constants'

export const Banner: FC<ModuleEmailCaptureFragment> = ({
  heading,
  text,
  thankYouHeading,
  thankYouText,
}) => {
  const isModal = false
  const [{ error, showThankYou }, dispatch] = useReducer(reducer, initialState)
  const { registerEmail } = useActions(dispatch, isModal)
  const [emailCapturelInViewRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      track(initialImpression({ isModal }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  useEffect(() => {
    if (showThankYou) {
      trySetLocalStorage(
        HAS_DISMISSED_NEWSLETTER_SUBSCRIPTION_MODAL_KEY,
        'true',
      )
    }
  }, [showThankYou])

  return (
    <ModuleSection
      backgroundColor="colorBackground03"
      data-testid="module-email-capture-banner"
      ref={emailCapturelInViewRef}
      padding={{ py: { xs: 6, md: 8 } }}
    >
      {showThankYou ? (
        <ThankYou heading={thankYouHeading} text={thankYouText} />
      ) : (
        <EmailForm
          heading={heading}
          text={text}
          onSubmit={registerEmail}
          privacyNoticeEventData={privacyNoticeClickEventData({ isModal })}
          error={error}
        />
      )}
    </ModuleSection>
  )
}
