import { z } from 'zod'
import {
  basketDataSchema,
  discountDataSchema,
  ecommerceItemSchema,
  ecommerceSchema,
  eventDataSchema,
  zodNumber,
} from '../../common.schema'

export const RemoveFromCartSchema = z.object({
  event: z.literal('remove_from_cart'),

  basket_data: basketDataSchema.extend({
    size: zodNumber,
  }),
  ecommerce: ecommerceSchema.extend({
    items: z.array(ecommerceItemSchema),
  }),
  event_data: eventDataSchema,
  discount_data: discountDataSchema.optional(),
})

export type RemoveFromCartEvent = z.infer<typeof RemoveFromCartSchema>
