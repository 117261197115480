import { useRouter } from '@moonpig/web-core-routing'
import { useMemo } from 'react'
import { parse } from '../../utils/parse-params/parse-params'

type UseQueryStringOverrideParams = {
  experimentKey: string
}

export const useQueryStringOverride = ({
  experimentKey,
}: UseQueryStringOverrideParams) => {
  const { getCurrentRoute } = useRouter()

  return useMemo(() => {
    const { params } = getCurrentRoute() as {
      params: { variation?: Array<string> }
    }

    const overrides = parse(params?.variation)

    if (Object.keys(overrides).includes(experimentKey)) {
      return overrides[experimentKey]
    }

    return null
  }, [experimentKey, getCurrentRoute])
}
