import { sanitizeStrings } from '@moonpig/web-core-analytics'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'

type PersonaliseEventProps = {
  productData: ProductData
  componentName: string
}

type ProductData = {
  category?: string
  id: string
  name?: string
  price?: number
  quantity?: number
  variant?: string
  bundle_id?: string
  design_id?: string
  group_card_id?: string
  project_id?: string
}

export const createPersonaliseGAEvent = ({
  productData,
  componentName,
}: PersonaliseEventProps) => {
  const product_variant = sanitizeStrings(productData.variant)
  return {
    event: 'personalise',
    content_data: {
      content_type:
        `product action | personalise | ${componentName}`.toLowerCase(),
      item_id: `${productData.id} | ${product_variant || 'n/a'}`.toLowerCase(),
    },
    event_data: {
      category: 'product action',
      action: 'personalise',
      label: `${productData.id} | ${product_variant || 'n/a'} | ${
        productData.quantity || 'n/a'
      }`.toLowerCase(),
      non_interaction: true,
      value: undefined,
    },
    product_data: {
      bundle_id: productData.bundle_id,
      design_id: productData.design_id,
      group_card_id: productData.group_card_id,
      product_brand: BRAND_LABEL.toLowerCase(),
      product_category: sanitizeStrings(productData.category),
      product_id: productData.id.toLowerCase(),
      product_name: sanitizeStrings(productData.name),
      product_price: productData.price ? productData.price / 100 : undefined,
      product_quantity: productData.quantity,
      product_variant,
      project_id: productData.project_id,
    },
    // Web only //
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}
