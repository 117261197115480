import { gql } from '@moonpig/web-core-graphql'

export const filterFacetFragment = gql`
  fragment filterFacetFragment on FilterFacet {
    all
    count
    nbaScore
    __typename
    label
    facetKey
    group
    isSelected
    isQuickFilter
    isUserSelected
    hasSelectedChildren
  }
`

export const filterToggleFragment = gql`
  fragment filterToggleFragment on ToggleFilter {
    facetKey
    group
    label
    isSelected
    isUserSelected
    icon
  }
`

export const filterInvertedToggleFragment = gql`
  fragment filterInvertedToggleFragment on InvertedToggleFilter {
    facetKey
    group
    label
    isSelected
    isUserSelected
    icon
  }
`

export const topLevelFacetFragment = gql`
  fragment topLevelFacetFragment on FilterFacet {
    ...filterFacetFragment
    children {
      ...midLevelFacetFragment
      ...header
    }
    __typename
  }
`

export const midLevelFacetFragment = gql`
  fragment midLevelFacetFragment on FilterFacet {
    ...filterFacetFragment
    children {
      ...bottomLevelFacetFragment
      ...header
    }
    __typename
  }
`

export const bottomLevelFacetFragment = gql`
  fragment bottomLevelFacetFragment on FilterFacet {
    ...filterFacetFragment
    __typename
  }
`

export const headerFragment = gql`
  fragment header on FilterHeader {
    name
    __typename
  }
`
