import React, { FC } from 'react'
import {
  ScreenReaderOnly,
  Text,
  Flex,
  Box,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { IconSystemChevronRight } from '@moonpig/launchpad-assets'
import { useFlag } from '@moonpig/web-explore-flags'
import { Checkbox } from '@moonpig/launchpad-forms'
import { colorValue } from '@moonpig/launchpad-theme'
import {
  FilterCategory as FilterCategoryType,
  FilterItem,
} from '../../../../services/types/services'
import { SelectedFiltersList } from './SelectedFiltersList'
import { useFindLocaleText } from '../../../../text-localisation'
import { FILTERS_MENU_HEADER_HEIGHT_PX } from '../../constants'
import { mapFilterItem } from '../../../../utils/GalleryFiltering/mappings'
import { AccordionContent, AccordionHeading } from '../AccordionMenu'

const ICON_SIZE = 24

const StyledLabel = styled(Text)`
  display: block;
  ${s({
    textAlign: 'left',
    typography: { xs: 'typeMobileDisplay05', md: 'typeDesktopBodyLabel' },
  })}
`

const StyledAccordionLabel = styled.label<{ hasSelectedChildren: boolean }>`
  pointer-events: none;
  ${({ hasSelectedChildren }) =>
    s({
      typography: 'typeBodyText',
      color: 'colorTextBody',
      fontWeight: hasSelectedChildren
        ? 'bold'
        : /* istanbul ignore next */ 'normal',
    })}
`

const StyledButton = styled.button.attrs(
  ({ isSubCategory }: { isSubCategory: boolean }) => ({
    className: isSubCategory && 'isSubCategory',
  }),
)<{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: ${FILTERS_MENU_HEADER_HEIGHT_PX};
  ${s({
    py: 5,
    px: 6,
    borderBottom: 1,
    borderColor: 'colorBorder04',
    color: 'colorTextLabel',
  })}

  &:hover {
    ${s({
      bgcolor: 'colorBackground02',
      borderColor: 'colorBlack05',
    })}
  }

  &:focus,
  &:focus-visible {
    outline-color: ${colorValue('colorInteractionFocusIndicator')};
    ${s({
      bgcolor: 'colorBackground02',
      borderColor: 'colorBlack05',
      color: 'colorInteractionFocusIndicator',
    })}

    &:active {
      ${s({
        bgcolor: 'colorBackground03',
        borderColor: 'colorBlack10',
      })}
    }
  }

  &.isSubCategory {
    ${s({
      borderTop: 1,
      borderColor: 'colorBorder03',
    })}

    & + &.isSubCategory,
    div:first-child + &.isSubCategory {
      ${s({
        borderTop: 0,
        py: 0,
        my: 0,
      })}
    }
  }
`

const StyledButtonTextWrapper = styled(Flex)`
  flex-direction: column;
  flex: 5;
  overflow: hidden;
  justify-content: space-between;
`

const StyledIconWrapper = styled(Flex)`
  flex: 1;
  justify-content: flex-end;
`

const StyledChevronRightIcon = styled(IconSystemChevronRight)`
  flex-shrink: 0;
`

type Props = {
  filter: FilterCategoryType
  onClick: (filter: FilterCategoryType | FilterItem, select?: boolean) => void
  level: number
}

export const FilterCategory: FC<Props> = ({ filter, level, onClick }) => {
  const [accordionExpanded, setAccordionExpanded] = React.useState(false)
  const localiseText = useFindLocaleText()
  const useProductTypeV1 = useFlag('search-product-type-v1')

  const handleCheckboxClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (filter.isSelected || accordionExpanded) {
      e.stopPropagation()
      return
    }
    setAccordionExpanded(true)
  }

  const renderButton = () => (
    <StyledButton
      data-testid="filter-category"
      onClick={() => onClick(filter)}
      key={filter.id}
      role="tab"
      aria-controls={`${filter.id}-filter-category-panel`}
      isSubCategory={level > 0}
    >
      <StyledButtonTextWrapper>
        <StyledLabel>{filter.label}</StyledLabel>
        <ScreenReaderOnly>
          {localiseText('find.filters_menu_panel.open', filter.label)}
        </ScreenReaderOnly>
        <SelectedFiltersList filter={filter} />
      </StyledButtonTextWrapper>
      <StyledIconWrapper>
        <StyledChevronRightIcon width={ICON_SIZE} height={ICON_SIZE} />
      </StyledIconWrapper>
    </StyledButton>
  )

  if (!useProductTypeV1) {
    return renderButton()
  }

  if (level === 0) {
    return renderButton()
  }

  return (
    <AccordionContent
      heading={
        <AccordionHeading
          onClick={() => setAccordionExpanded(!accordionExpanded)}
        >
          <Flex flexDirection="row" alignItems="center">
            <Box onClick={handleCheckboxClick}>
              <Checkbox
                onChange={() => onClick(filter, !filter.isSelected)}
                checked={!!filter.isSelected}
                value={filter.id}
                id={filter.id}
                indeterminate={filter.hasSelectedChildren && !filter.isSelected}
              />
            </Box>
            <StyledAccordionLabel
              htmlFor={filter.id}
              hasSelectedChildren={filter.hasSelectedChildren}
            >
              {filter.label}
            </StyledAccordionLabel>
          </Flex>
        </AccordionHeading>
      }
      value={filter.label}
    >
      {filter.children?.map(filterItem =>
        mapFilterItem({
          filterItem,
          onClick,
          indentation: 6,
          level: level + 1,
        }),
      )}
    </AccordionContent>
  )
}
