import { GetExperimentVariant } from '@moonpig/web-core-eppo/server'
import { VariationType } from '@moonpig/web-core-eppo/types'
import { graphQlExperimentsWhitelist } from './graphQlExperimentsWhitelist'

const getActiveExperiments = (currentDate: Date): string[] => {
  return graphQlExperimentsWhitelist
    .filter(e => e.expiry >= currentDate)
    .map(e => e.key)
}

export const buildExperimentsHeader = (
  sitespectExperiments: {
    [key: string]: string
  },
  getEppoExperimentVariant?: GetExperimentVariant,
  currentDate: Date = new Date(),
): string => {
  const experimentWhitelist = getActiveExperiments(currentDate)

  const headerParts = experimentWhitelist
    .map(key => {
      if (sitespectExperiments[key]) {
        return `${key}=${sitespectExperiments[key]}`
      }
      if (getEppoExperimentVariant) {
        const variant = getEppoExperimentVariant(key, {
          fallback: 'fallback',
          type: VariationType.STRING,
        })
        return `${key}=${variant}`
      }

      return null
    })
    .filter(Boolean)

  return headerParts.sort().join(';')
}
