import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { Carousel } from '@moonpig/web-shared-components'
import { USP } from './components/USP'
import { ModuleUspCarouselFragment } from './__generated__/fragment'
import { Module } from '../types'
import { ModuleSection } from '../../components/ModuleContainer'

export const USPCarouselModule: FC<Module<ModuleUspCarouselFragment>> = ({
  module: { items },
  context: { backgroundColor },
}) => {
  return (
    <ModuleSection
      data-testid="module-usp-carousel"
      padding={{ py: 0 }}
      margin={{ my: 8 }}
      backgroundColor={backgroundColor}
      withContainer
    >
      <Carousel centerSlides>
        {items.map(({ title, description, icon }) => {
          return (
            <Box key={title} marginX={{ xs: 0, md: 8 }}>
              <USP
                title={title}
                description={description}
                image={
                  icon
                    ? {
                        iconImageUrl: icon.url,
                        lazyLoad: true,
                        alt: icon.description,
                      }
                    : undefined
                }
              />
            </Box>
          )
        })}
      </Carousel>
    </ModuleSection>
  )
}
