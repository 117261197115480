import { z } from 'zod'
// Core
import { CustomerSchema } from '../schemas/core/app/customer.schema'
// Customer
import { LogoutSchema } from '../schemas/customer/account/logout.schema'
import { LogoutErrorSchema } from '../schemas/customer/account/logoutError.schema'
import { LogoutFailureSchema } from '../schemas/customer/account/logoutFailure.schema'
// Explore
import { SearchSchema } from '../schemas/explore/find/search.schema'
// Multiple teams
import { AddToCartSchema } from '../schemas/multiple_teams/addToCart.schema'
import { ImpressionSchema } from '../schemas/multiple_teams/impression.schema'
import { MediaEditSchema } from '../schemas/multiple_teams/mediaEdit.schema'
import { MediaUpdateSchema } from '../schemas/multiple_teams/mediaUpdate.schema'
import { ScreenViewSchema } from '../schemas/multiple_teams/screenView.schema'
import { SelectContentSchema } from '../schemas/multiple_teams/selectContent.schema'
import { SelectItemSchema } from '../schemas/multiple_teams/selectItem.schema'
import { ViewItemSchema } from '../schemas/multiple_teams/viewItem.schema'
import { ViewItemListSchema } from '../schemas/multiple_teams/viewItemList.schema'
// No owner
import { AbtestSchema } from '../schemas/no_owner/abtest.schema'
import { CampaignDetailsSchema } from '../schemas/no_owner/campaignDetails.schema'
import { CookieConsentSchema } from '../schemas/no_owner/cookieConsent.schema'
import { GenericErrorSchema } from '../schemas/no_owner/genericError.schema'
import { GenericEventSchema } from '../schemas/no_owner/genericEvent.schema'
import { MarketingConsentSchema } from '../schemas/no_owner/marketingConsent.schema'
import { ShareSchema } from '../schemas/no_owner/share.schema'
// Personalise
import { MediaUploadFailureSchema } from '../schemas/personalise/editor-services/mediaUploadFailure.schema'
import { MediaUploadSuccessSchema } from '../schemas/personalise/editor-services/mediaUploadSuccess.schema'
import { EditorDesignUpdateSchema } from '../schemas/personalise/editor/editorDesignUpdate.schema'
import { EditorExitSchema } from '../schemas/personalise/editor/editorExit.schema'
import { EditorPreviewSchema } from '../schemas/personalise/editor/editorPreview.schema'
import { MediaCompleteSchema } from '../schemas/personalise/media-cards/mediaComplete.schema'
import { MediaPauseSchema } from '../schemas/personalise/media-cards/mediaPause.schema'
import { MediaProgressSchema } from '../schemas/personalise/media-cards/mediaProgress.schema'
import { MediaStartSchema } from '../schemas/personalise/media-cards/mediaStart.schema'
// Purchase
import { ViewXsellResultsSchema } from '../schemas/purchase/attach/viewXsellResults.schema'
import { AccountDelinkErrorSchema } from '../schemas/purchase/auth/accountDelinkError.schema'
import { AccountDelinkFailureSchema } from '../schemas/purchase/auth/accountDelinkFailure.schema'
import { AccountDelinkSuccessSchema } from '../schemas/purchase/auth/accountDelinkSuccess.schema'
import { AccountLinkErrorSchema } from '../schemas/purchase/auth/accountLinkError.schema'
import { AccountLinkFailureSchema } from '../schemas/purchase/auth/accountLinkFailure.schema'
import { AccountLinkSuccessSchema } from '../schemas/purchase/auth/accountLinkSuccess.schema'
import { LoginSchema } from '../schemas/purchase/auth/login.schema'
import { LoginErrorSchema } from '../schemas/purchase/auth/loginError.schema'
import { LoginFailureSchema } from '../schemas/purchase/auth/loginFailure.schema'
import { SignUpSchema } from '../schemas/purchase/auth/signUp.schema'
import { SignUpErrorSchema } from '../schemas/purchase/auth/signUpError.schema'
import { SignUpFailureSchema } from '../schemas/purchase/auth/signUpFailure.schema'
import { SocialAuthenticationExceptionSchema } from '../schemas/purchase/auth/socialAuthenticationException.schema'
import { AddDiscountSchema } from '../schemas/purchase/buy/addDiscount.schema'
import { BeginCheckoutSchema } from '../schemas/purchase/buy/beginCheckout.schema'
import { DeleteRecipientSchema } from '../schemas/purchase/buy/deleteRecipient.schema'
import { RemoveDiscountSchema } from '../schemas/purchase/buy/removeDiscount.schema'
import { RemoveFromCartSchema } from '../schemas/purchase/buy/removeFromCart.schema'
import { SelectRecipientSchema } from '../schemas/purchase/buy/selectRecipient.schema'
import { ViewCartSchema } from '../schemas/purchase/buy/viewCart.schema'
import { PurchaseSchema } from '../schemas/purchase/payment/purchase.schema'
// Search
import { SearchInputClearSchema } from '../schemas/search/searchInputClear.schema'
import { SearchSuggestionSchema } from '../schemas/search/searchSuggestion.schema'
import { SearchSuggestionClickSchema } from '../schemas/search/searchSuggestionClick.schema'
import { ViewGalleryResultsSchema } from '../schemas/search/viewGalleryResults.schema'
// Shared
import { AddRecipientSchema } from '../schemas/shared/addresses/addRecipient.schema'
import { RecipientErrorSchema } from '../schemas/shared/addresses/recipientError.schema'
import { UpdateRecipientSchema } from '../schemas/shared/addresses/updateRecipient.schema'
import { AddToWishlistSchema } from '../schemas/shared/favourites/addToWishlist.schema'
import { RemoveFromWishlistSchema } from '../schemas/shared/favourites/removeFromWishlist.schema'
import { PersonaliseSchema } from '../schemas/shared/products/personalise.schema'
import { DiscountErrorSchema } from '../schemas/shared/promotions/discountError.schema'
import { DiscountFailureSchema } from '../schemas/shared/promotions/discountFailure.schema'
import { CreateReminderSchema } from '../schemas/shared/reminders/createReminder.schema'
import { DeleteReminderSchema } from '../schemas/shared/reminders/deleteReminder.schema'
import { EditReminderSchema } from '../schemas/shared/reminders/editReminder.schema'
import { UpdateReminderSchema } from '../schemas/shared/reminders/updateReminder.schema'

// Event list
import { SelectPromotionSchema } from '../schemas/no_owner/selectPromotion.schema'
import { ViewPromotionSchema } from '../schemas/no_owner/viewPromotion.schema'

const CatchAllSchema = z.object({}).catchall(z.any())
export const EventObjectSchema = CatchAllSchema

export const ManualEventObjectSchema = z.discriminatedUnion('event', [
  AbtestSchema,
  AccountDelinkErrorSchema,
  AccountDelinkFailureSchema,
  AccountDelinkSuccessSchema,
  AccountLinkErrorSchema,
  AccountLinkFailureSchema,
  AccountLinkSuccessSchema,
  AddDiscountSchema,
  AddRecipientSchema,
  AddToCartSchema,
  AddToWishlistSchema,
  BeginCheckoutSchema,
  CampaignDetailsSchema,
  CookieConsentSchema,
  CreateReminderSchema,
  CustomerSchema,
  DeleteRecipientSchema,
  DeleteReminderSchema,
  DiscountErrorSchema,
  DiscountFailureSchema,
  EditorDesignUpdateSchema,
  EditorExitSchema,
  EditorPreviewSchema,
  EditReminderSchema,
  GenericErrorSchema,
  GenericEventSchema,
  ImpressionSchema,
  LoginErrorSchema,
  LoginFailureSchema,
  LoginSchema,
  LogoutErrorSchema,
  LogoutFailureSchema,
  LogoutSchema,
  MarketingConsentSchema,
  MediaCompleteSchema,
  MediaEditSchema,
  MediaPauseSchema,
  MediaProgressSchema,
  MediaStartSchema,
  MediaUpdateSchema,
  MediaUploadFailureSchema,
  MediaUploadSuccessSchema,
  PersonaliseSchema,
  PurchaseSchema,
  RecipientErrorSchema,
  RemoveDiscountSchema,
  RemoveFromCartSchema,
  RemoveFromWishlistSchema,
  ScreenViewSchema,
  SearchInputClearSchema,
  SearchSchema,
  SearchSuggestionClickSchema,
  SearchSuggestionSchema,
  SelectContentSchema,
  SelectItemSchema,
  SelectPromotionSchema,
  SelectRecipientSchema,
  ShareSchema,
  SignUpErrorSchema,
  SignUpFailureSchema,
  SignUpSchema,
  SocialAuthenticationExceptionSchema,
  UpdateRecipientSchema,
  UpdateReminderSchema,
  ViewCartSchema,
  ViewGalleryResultsSchema,
  ViewItemListSchema,
  ViewItemSchema,
  ViewPromotionSchema,
  ViewXsellResultsSchema,
])

export type EventObject = z.infer<typeof EventObjectSchema>
