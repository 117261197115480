import { z } from 'zod'
import {
  eventDataSchema,
  filterDataSchema,
  resultsDataSchema,
  zodProductCategoryString,
  zodResultsDataInputTypeEnum,
} from '../common.schema'

export const ViewGalleryResultsSchema = z.object({
  event: z.literal('view_gallery_results'),

  event_data: eventDataSchema.extend({
    label: zodProductCategoryString,
  }),
  results_data: resultsDataSchema.extend({
    input_type: zodResultsDataInputTypeEnum.or(
      z.enum(['nba', 'toggle', 'quick-filters']),
    ),
  }),
  filter_data: filterDataSchema,
})

export type ViewGalleryResultsEvent = z.infer<typeof ViewGalleryResultsSchema>
