/* eslint-disable no-shadow */
import React, { FC, useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import {
  Box,
  Container,
  Flex,
  SecondaryButton,
} from '@moonpig/launchpad-components'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { useStoreId } from '@moonpig/web-core-stores'
import { DateTime } from 'luxon'
import { Carousel } from '@moonpig/web-shared-components'
import { UpcomingOccasionCard } from '../UpcomingOccasionCard/UpcomingOccasionCard'
import { GetUpcomingOccasions_reminders as Reminder } from '../../queries/types-graphql'
import { ModuleHeader } from '../ModuleContainer'
import {
  CARD_DESKTOP_MULTIPLE_WIDTH,
  CARD_DESKTOP_SINGLE_WIDTH,
  CARD_MIN_HEIGHT,
  CARD_MOBILE_MULTIPLE_WIDTH,
  CARD_MOBILE_SINGLE_WIDTH,
  TRACKING_EVENTS_LABEL,
} from './constants'
import { useFindLocaleText } from '../../text-localisation'
import { selectContentGAEvent } from '../../analytics/commonGAEvents'

interface UpcomingOccasionsCarouselProps {
  reminders: Reminder[]
}

export const upcomingOccasionsCarouselImpressionGAEvent = (
  reminders: Reminder[],
  label: string,
) => {
  const todaysDate = DateTime.fromISO(DateTime.now().toFormat('yyyy-MM-dd'))

  return {
    content_data: undefined,
    event: 'view_item_list',
    event_data: {
      action: 'view item list',
      category: 'product action',
      label,
      non_interaction: true,
    },
    ecommerce: {
      currency: 'gbp',
      items: reminders.map((reminder: Reminder, index: number) => {
        const daysUntilEvent = Math.abs(
          DateTime.fromISO(reminder.nextDate).diff(todaysDate, 'days').days,
        )

        return {
          index: index + 1,
          item_brand: 'moonpig',
          item_category: 'reminders',
          item_id: reminder.id,
          item_list_name: label,
          item_name: `${reminder.occasion.toLocaleLowerCase()} | ${Math.floor(
            daysUntilEvent,
          )} days`,
        }
      }),
      _clear: true,
    },
    error_data: undefined,
  }
}

export const upcomingOccasionsItemSelectedGAEvent = (
  reminder: Reminder,
  totalReminders: number,
  index: number,
  label: string,
) => {
  const todaysDate = DateTime.fromISO(DateTime.now().toFormat('yyyy-MM-dd'))
  const daysUntilEvent = Math.abs(
    DateTime.fromISO(reminder.nextDate).diff(todaysDate, 'days').days,
  )

  return {
    event: 'select_item',
    event_data: {
      action: 'select item',
      category: 'product action',
      label: `${label} | ${
        index + 1
      }/${totalReminders} | ${reminder.occasion.toLocaleLowerCase()} | ${Math.floor(
        daysUntilEvent,
      )} days`,
      non_interaction: true,
    },
    ecommerce: {
      currency: 'gbp',
      items: [
        {
          index: index + 1,
          item_brand: 'moonpig',
          item_category: 'reminders',
          item_id: reminder.id,
          item_list_name: label,
          item_name: `${reminder.occasion.toLocaleLowerCase()} | ${Math.floor(
            daysUntilEvent,
          )} days`,
        },
      ],
      _clear: true,
    },
    error_data: undefined,
  }
}

export const UpcomingOccasionsCarousel: FC<UpcomingOccasionsCarouselProps> = ({
  reminders,
}) => {
  const localiseText = useFindLocaleText()
  const storeId = useStoreId()

  const [carouselInViewRef, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  const impressionEventData = upcomingOccasionsCarouselImpressionGAEvent(
    reminders,
    TRACKING_EVENTS_LABEL,
  )

  useEffect(() => {
    if (inView && impressionEventData) {
      trackGAEvent(impressionEventData)
    }
  }, [inView, impressionEventData])

  const onShopClicked = useCallback(
    (reminder: Reminder, index: number) => {
      trackGAEvent(
        upcomingOccasionsItemSelectedGAEvent(
          reminder,
          reminders.length,
          index,
          TRACKING_EVENTS_LABEL,
        ),
      )
    },
    [reminders.length],
  )

  const onViewAllRemindersClick = () => {
    trackGAEvent(
      selectContentGAEvent({
        type: 'button',
        context: 'upcoming occasions',
        action: 'view all reminders',
        category: 'reminders',
      }),
    )
  }

  const calculateWidth = () => {
    const isSingleReminder = reminders.length === 1
    return {
      xs: isSingleReminder
        ? CARD_MOBILE_SINGLE_WIDTH
        : CARD_MOBILE_MULTIPLE_WIDTH,
      md: isSingleReminder
        ? CARD_DESKTOP_SINGLE_WIDTH
        : CARD_DESKTOP_MULTIPLE_WIDTH,
    }
  }

  return (
    <Box
      ref={carouselInViewRef}
      data-testid="web-browse-upcoming-occasions-list"
    >
      <Container limitWidth padding={{ xs: 0, md: 8 }}>
        <ModuleHeader
          title={localiseText('find.upcoming_occasions')}
          subtitle={localiseText(
            'find.here_is_what_is_coming_up_in_the_next_30_days',
          )}
        />
        <Carousel centerNonScrollableItems>
          {reminders.map((reminder, index) => (
            <Box
              key={reminder.id}
              data-testid="web-browse-upcoming-occasions-list-item"
              width={calculateWidth()}
              ml={{ xs: 4, md: 5 }}
              mr={{ xs: 2, md: 5 }}
            >
              <UpcomingOccasionCard
                onShopClicked={() => onShopClicked(reminder, index)}
                maxHeight={CARD_MIN_HEIGHT}
                minHeight={CARD_MIN_HEIGHT}
                key={reminder.id}
                reminder={{
                  ...reminder,
                  nextDate: new Date(reminder.nextDate),
                }}
              />
            </Box>
          ))}
        </Carousel>
        <Flex justifyContent="space-around" pt={6}>
          <SecondaryButton
            href={`/${storeId}/account/reminders`}
            onClick={onViewAllRemindersClick}
          >
            {localiseText('find.reminder_view_all_cta')}
          </SecondaryButton>
        </Flex>
      </Container>
    </Box>
  )
}
