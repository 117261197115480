import { deduplicateValidationErrors } from './helpers/dedupe'
import { transformEventPayload } from './schemas/common.schema'
import { EventObject, ManualEventObjectSchema } from './schemaTypes'

type Value =
  | string
  | Value[]
  | { [key: string]: Value }
  | boolean
  | number
  | undefined

const sanitizeValue = <T extends Value>(value: T): Value => {
  if (typeof value === 'string') {
    return value.replace(/%/g, '')
  }
  if (Array.isArray(value)) {
    return value.map(sanitizeValue)
  }
  if (typeof value === 'object' && value) {
    return Object.entries(value).reduce<{ [key: string]: Value }>(
      (acc, [key, item]) => {
        acc[key] = sanitizeValue(item)
        return acc
      },
      {},
    )
  }
  return value
}

export const trackGAEvent = (event: EventObject) => {
  if (typeof window !== 'undefined') {
    const sanitizedEvent = sanitizeValue(event)
    let result = sanitizedEvent
    window.dataLayer = window.dataLayer || []

    const validationResult = ManualEventObjectSchema.safeParse(sanitizedEvent)

    if (validationResult.success) {
      result = transformEventPayload(validationResult.data) as EventObject

      window.dataLayer.push(result)

      return
    }

    if (typeof sanitizedEvent === 'object' && 'ecommerce' in sanitizedEvent) {
      window.dataLayer.push({ event: 'ecom_clear', ecommerce: null })
    }

    if (window.location.href?.includes('show-analytics-debug')) {
      ;(result as Record<string, unknown>).validation_data = {
        status: 'fail',
      }

      window.dataLayer.push(result)

      let errors = validationResult.error.issues.map(e => ({
        key: e.path.join('.'),
        error_type: e.code,
        criteria:
          e.code === 'invalid_union_discriminator'
            ? 'Invalid event name'
            : e.message,
        // @ts-expect-error: we don't care if the property is there or not
        expected: e.expected || '',
        // @ts-expect-error: same as above
        received: e.received || '',
      }))

      if ((result as { event: string }).event === 'view_item_list') {
        errors = deduplicateValidationErrors(errors)
      }

      errors.forEach(e => {
        window.dataLayer.push({
          event: 'validation_error',

          validation_data: {
            name: (result as { event: string }).event,
            ...e,
          },
        })
      })

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.groupCollapsed(
          '%cGA validation error:',
          'color: red; font-weight: bold; background: yellow',
        )
        // eslint-disable-next-line no-console
        console.log('Errors:\n', errors)
        // eslint-disable-next-line no-console
        console.log('Original event:\n', result)
        // eslint-disable-next-line no-console
        console.log(new Error().stack)
        // eslint-disable-next-line no-console
        console.groupEnd()
      }
    } else {
      window.dataLayer.push(result)
    }
  }
}
