import { z } from 'zod'
import {
  eventDataSchema,
  filterDataSchema,
  resultsDataSchema,
  zodResultsDataInputTypeEnum,
} from '../../common.schema'

export const SearchSchema = z.object({
  event: z.literal('search'),

  event_data: eventDataSchema,
  results_data: resultsDataSchema.extend({
    input_type: zodResultsDataInputTypeEnum.or(
      z.enum(['nba', 'toggle', 'quick-filters']),
    ),
  }),
  filter_data: filterDataSchema,
})

export type SearchEvent = z.infer<typeof SearchSchema>
