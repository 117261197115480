import { z } from 'zod'
import {
  eventDataSchema,
  userDataSchema,
  zodAddressCountString,
  zodLowerCaseString,
} from '../../common.schema'

export const LogoutSchema = z.object({
  event: z.literal('logout'),

  user_data: userDataSchema.required().extend({
    address_count: zodAddressCountString.optional(),
    email: zodLowerCaseString.optional(),
  }),
  event_data: eventDataSchema,
})

export type LogoutEvent = z.infer<typeof LogoutSchema>
