import type { Schema } from './types'

export const shared: Schema = {
  'disable-app-banner': { default: false },
  'disable-cookie-banner': { default: false },
  'disable-sitespect': { default: false },
  'merge-eppo-experiments': { default: false },
  'enable-sailthru': { default: false },
  'show-dev-widget': { default: false },
  'third-party-scripts': { default: true },
  'enable-google-one-tap': { default: false },
} as const
