import { EppoClient } from '@eppo/js-client-sdk-common'
import { DefaultSubjectAttributes } from '../../shared/DefaultSubjectAttributes'
import { EppoConfiguration } from '../../types'
import { setupGetAllExperimentVariants } from '../setupGetAllExperimentVariants/setupGetAllExperimentVariants'
import { setupGetExperimentVariant } from '../setupGetExperimentVariant/setupGetExperimentVariant'
import { parse } from '../../utils/parse-params/parse-params'

type SetupEppo = {
  sessionId: string
  eppoClientPromise: Promise<EppoClient | null>
  defaultAttributes: DefaultSubjectAttributes
  overrideParams: Array<string>
}

export const setupEppo = async ({
  sessionId,
  eppoClientPromise,
  defaultAttributes,
  overrideParams,
}: SetupEppo) => {
  const client = await eppoClientPromise

  const eppoConfiguration = client?.getFlagConfigurations() as EppoConfiguration

  const overrides = parse(overrideParams)

  const getExperimentVariant = setupGetExperimentVariant({
    client,
    subjectKey: sessionId,
    defaultAttributes,
    overrides,
  })

  const getAllExperimentVariants = setupGetAllExperimentVariants({
    client,
    subjectKey: sessionId,
    defaultAttributes,
    overrides,
  })

  return { eppoConfiguration, getExperimentVariant, getAllExperimentVariants }
}
