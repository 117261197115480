import React, { FC } from 'react'
import {
  Header as RadixAccordionHeader,
  Trigger as RadixAccordionTrigger,
} from '@radix-ui/react-accordion'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { IconSystemChevronDown } from '@moonpig/launchpad-assets'
import { colorValue } from '@moonpig/launchpad-theme'

const ANIMATION_DURATION = 300
const REDUCED_MOTION_ANIMATION_DURATION = 1200
const ICON_SIZE = 24

const StyledAccordionHeader = styled(RadixAccordionHeader)`
  margin: 0;
`

const StyledChevron = styled(IconSystemChevronDown)`
  transition: transform ${ANIMATION_DURATION}ms;

  @media (prefers-reduced-motion) {
    transition: transform ${REDUCED_MOTION_ANIMATION_DURATION}ms;
  }
`

const StyledAccordionTrigger = styled(RadixAccordionTrigger)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${s({
    color: 'colorTextHeadline',
    p: 3,
  })}

  &[data-state="open"] {
    ${s({
      borderBottom: 1,
      borderColor: 'colorBorder04',
    })}
  }

  &[data-state='open'] ${StyledChevron} {
    transform: rotate(180deg);
  }

  &:hover {
    ${s({
      bgcolor: 'colorBackground02',
      borderColor: 'colorBorder04',
    })}
  }

  &:active {
    ${s({
      bgcolor: 'colorBackground03',
      borderColor: 'colorBorder03',
    })}
  }

  &:focus,
  &:focus-visible {
    outline-color: ${colorValue('colorInteractionFocusIndicator')};
    ${s({
      bgcolor: 'colorBackground02',
      borderColor: 'colorBorder04',
      color: 'colorInteractionFocusIndicator',
    })}
  }

  &[data-state='open'] {
    ${s({
      color: 'colorInteractionSelectedState',
    })}

    &:hover,
    &:focus {
      ${s({ color: 'colorInteractionButtonHover' })}
    }
  }
`

const StyledIconContainer = styled.div`
  ${s({
    width: '44px',
    height: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })}
`

export const AccordionHeading: FC<{ onClick: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <StyledAccordionHeader as="span">
      <StyledAccordionTrigger onClick={onClick}>
        <div>{children}</div>
        <StyledIconContainer>
          <StyledChevron width={ICON_SIZE} height={ICON_SIZE} />
        </StyledIconContainer>
      </StyledAccordionTrigger>
    </StyledAccordionHeader>
  )
}
