import { z } from 'zod'
import {
  contentDataSchema,
  eventDataSchema,
  productDataSchema,
} from '../../common.schema'

export const PersonaliseSchema = z.object({
  event: z.literal('personalise'),

  content_data: contentDataSchema,
  event_data: eventDataSchema,
  product_data: productDataSchema,
})

export type PersonaliseEvent = z.infer<typeof PersonaliseSchema>
