import { IAssignmentEvent, offlineInit } from '@eppo/js-client-sdk'
import { trackExperiment } from '../trackExperiment/trackExperiment'
import { EppoConfiguration } from '../../types'

type CreateEppoClient = {
  flagsConfiguration: EppoConfiguration
}

const logAssignment = (assignment: IAssignmentEvent) => {
  trackExperiment(assignment)
}

let cachedEppoConfiguration: EppoConfiguration = null

export const createEppoClient = ({ flagsConfiguration }: CreateEppoClient) => {
  if (flagsConfiguration) {
    cachedEppoConfiguration = flagsConfiguration
  }

  if (!cachedEppoConfiguration) return null

  return offlineInit({
    flagsConfiguration: cachedEppoConfiguration,
    isObfuscated: false,
    assignmentLogger: { logAssignment },
  })
}
