import {
  bottomLevelFacetFragment,
  midLevelFacetFragment,
  topLevelFacetFragment,
  filterInvertedToggleFragment,
  headerFragment,
  filterToggleFragment,
} from '../filters/__generated__/fragments'
import { FilterHeader, FilterCategory, ApplicableFilter } from './services'

type FilterFacetGqlType = Overwrite<
  topLevelFacetFragment | midLevelFacetFragment | bottomLevelFacetFragment,
  {
    children?:
      | topLevelFacetFragment['children']
      | midLevelFacetFragment['children']
      | null
  }
>

export type FilterItemGqlType =
  | FilterFacetGqlType
  | filterToggleFragment
  | filterInvertedToggleFragment
  | headerFragment

const mapGqlFilterFacetToDomain = (
  gqlFilter:
    | FilterFacetGqlType
    | filterToggleFragment
    | filterInvertedToggleFragment,
): FilterCategory | ApplicableFilter => {
  if (
    gqlFilter.__typename === 'FilterFacet' &&
    gqlFilter.children &&
    gqlFilter.children.length
  ) {
    const children = gqlFilter.children.map(child =>
      mapGqlFilterItemToDomain(child as FilterItemGqlType),
    )
    return {
      __typename: 'FilterCategory',
      id: gqlFilter.facetKey,
      parent: gqlFilter.group,
      count: gqlFilter.count,
      label: gqlFilter.label,
      nbaScore: gqlFilter.nbaScore || undefined,
      isSelected: gqlFilter.isUserSelected,
      isQuickFilter: !!gqlFilter.isQuickFilter,
      hasSelectedChildren: gqlFilter.hasSelectedChildren,
      children,
    }
  }

  if (gqlFilter.__typename === 'ToggleFilter') {
    return {
      __typename: 'FilterToggle',
      id: gqlFilter.facetKey,
      parent: gqlFilter.group,
      isSelected: gqlFilter.isUserSelected,
      label: gqlFilter.label,
      ...(gqlFilter.icon && { icon: gqlFilter.icon }),
    }
  }

  if (gqlFilter.__typename === 'InvertedToggleFilter') {
    return {
      __typename: 'FilterInvertedToggle',
      id: gqlFilter.facetKey,
      parent: gqlFilter.group,
      isSelected: gqlFilter.isUserSelected,
      label: gqlFilter.label,
      ...(gqlFilter.icon && /* istanbul ignore next */ {
        icon: gqlFilter.icon,
      }),
    }
  }

  return {
    __typename: 'Filter',
    id: gqlFilter.facetKey,
    parent: gqlFilter.group,
    count: gqlFilter.count,
    isSelected: gqlFilter.isUserSelected,
    label: gqlFilter.label,
    nbaScore: gqlFilter.nbaScore || undefined,
  }
}

const mapGqlFilterHeaderToDomain = (
  gqlFacet: headerFragment,
): FilterHeader => ({
  __typename: gqlFacet.__typename,
  label: gqlFacet.name,
})

export const mapGqlFilterItemToDomain = (
  gqlFacet: FilterItemGqlType,
): ApplicableFilter | FilterCategory | FilterHeader => {
  return gqlFacet.__typename === 'FilterFacet' ||
    gqlFacet.__typename === 'ToggleFilter' ||
    gqlFacet.__typename === 'InvertedToggleFilter'
    ? mapGqlFilterFacetToDomain(gqlFacet)
    : mapGqlFilterHeaderToDomain(gqlFacet)
}
