import { logger } from '@moonpig/web-core-monitoring'

export const parse = (params: Array<string>) => {
  return params.reduce<{ [key: string]: string }>((accumulator, current) => {
    const override = current.split(/:(.*)/).slice(0, -1)

    if (override.length !== 2) {
      logger.fixImmediately(
        `Expect experiment:value but instead got ${current}`,
      )
      return accumulator
    }

    const [flagKey, value] = override

    accumulator[flagKey] = value
    return accumulator
  }, {})
}
