import { setUIHCookie } from '@moonpig/web-shared-utils'
import { useAllSitespectExperiments } from '@moonpig/web-core-experiments'
import { useStoreId } from '@moonpig/web-core-stores'
import { useCallback } from 'react'
import { useRouter } from '@moonpig/web-core-routing'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { useCoreFlag } from '@moonpig/web-core-flags'
import { useAllEppoExperiments } from '@moonpig/web-core-eppo/browser'
import {
  PageTypeEnum,
  ButtonActionName,
  ProductInfoProduct,
  ProductItemEventEnum,
} from '../../types'
import { useHandleAddToBasket } from '../../utils/addToBasket'
import { useDigitalGiftingCardOptions } from '../../utils/useDigitalGiftingCardOptions/useDigitalGiftingCardOptions'
import { useProductInfoLocaleText } from '../../components/ProductInfo/ProductInfo.locale'
import { useProductsLocaleText } from '../../locale'
import { useTracking } from '../../contexts/tracking'
import { useAddDigitalGiftWithCardToBasket } from '../../utils/addDigitalGiftToBasket/useAddDigitalGiftWithCardToBasket'
import { Journeys } from '../../utils/addDigitalGiftToBasket/Journeys'
import { createProductItemGAEvent } from '../../analytics'
import { useProductStore } from '../../contexts/product'
import { useBasket } from '../../contexts/basket'
import { useProductRequiresPersonalisationQuery } from '../../queries/useProductRequiresPersonalisationQuery'
import { isCardProduct } from '../../utils/isCardProduct'
import { E_CARD_SIZE_ID } from '../../constants'
import { createButtons } from './createButtons'
import { useAddDigitalGiftToBasket } from '../../utils/addDigitalGiftToBasket/useAddDigitalGiftToBasket'
import { useProductModal } from '../../contexts/productModal/Context'

type Props = {
  pageType: PageTypeEnum
  productType: string | undefined
}

const DIGITAL_GIFT_CATEGORIES = [
  DepartmentsEnum.DIGITAL_GIFTS,
  DepartmentsEnum.DIGITAL_GIFT_CARDS,
]

const isDigitalGift = (product: ProductInfoProduct) =>
  DIGITAL_GIFT_CATEGORIES.includes(product.category.department)

export const useCreateButtons = ({ pageType, productType }: Props) => {
  const [{ product, variant, quantity, addon, productContext }] =
    useProductStore()
  const [{ digitalGift, lastLineItem, basketId }, { setError }] = useBasket()
  const { close: closeModal } = useProductModal()
  const storeId = useStoreId()
  const { personaliseProduct: trackPersonaliseProduct, selectContent } =
    useTracking()
  const mergeEppoExperiments = useCoreFlag('merge-eppo-experiments')

  const sitespectExperiments = useAllSitespectExperiments()
  const eppoExperiments = useAllEppoExperiments()

  const experiments = mergeEppoExperiments
    ? { ...sitespectExperiments, ...eppoExperiments }
    : sitespectExperiments
  const localiseProductInfoText = useProductInfoLocaleText()
  const localiseProductsText = useProductsLocaleText()
  const { getCanSeeCardOptions, seeCardOptionsForProduct } =
    useDigitalGiftingCardOptions()
  const router = useRouter()

  const { params } = router.getCurrentRoute<'search'>()
  const groupCardProjectId = params.groupCardProject
  const isCard = isCardProduct(product.category.department)
  const isEcard = variant.key === E_CARD_SIZE_ID
  const requiresEditing = useProductRequiresPersonalisationQuery({
    productId: product.id,
    skip: !isCard,
  })

  const { handleAddToBasket, addingToBasket } = useHandleAddToBasket({
    pageLocation: pageType,
    soldOutMessage: localiseProductsText('messaging.sold_out'),
    itsNotYouMessage: localiseProductsText('messaging.its_not_you'),
  })

  const { addDigitalGiftWithCardToBasket, loading: addingDigitalGiftToBasket } =
    useAddDigitalGiftWithCardToBasket()

  const { addDigitalGiftToBasket } = useAddDigitalGiftToBasket()

  const onAddToBasket = useCallback(
    async ({
      buttonActionName = ButtonActionName.ADD_TO_BASKET,
      redirectUser,
    }: {
      buttonActionName?: ButtonActionName
      redirectUser?: boolean
    }) => {
      setError(false)

      try {
        await handleAddToBasket({
          quantity,
          product,
          variant,
          filters: productContext?.filters || [],
          experiments,
          searchTerm: params.q || '',
          selectedAddon: addon,
          itemIndex: product?.productIndex || 0,
          productsLength: productContext?.totalNumberOfProducts || 1,
          buttonActionName,
          requiresEditingValue: requiresEditing.value,
          componentName: 'product details',
          redirectUser,
        })
      } catch (error) {
        setError(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      experiments,
      handleAddToBasket,
      product,
      quantity,
      requiresEditing.value,
      variant,
    ],
  )

  const addBasketCrossSellGiftToBasket = useCallback(async () => {
    await onAddToBasket({
      redirectUser: false,
    })

    closeModal()
  }, [onAddToBasket, closeModal])

  const addDigitalGiftCardToBasket = useCallback(
    async (digitalGiftProduct: ProductInfoProduct) => {
      setUIHCookie({ product })
      setError(false)
      try {
        await addDigitalGiftWithCardToBasket({
          productId: digitalGiftProduct.id,
          digitalGiftProductMasterVariant: {
            ...digitalGiftProduct.masterVariant,
            addons: [],
          },
          journey: Journeys.EXPERIENCE_GIFT_FIRST_UPGRADED,
          region: storeId,
          card: { id: product.id, sku: product.masterVariant.sku },
        })
      } catch (e) {
        setError(true)
      }
    },
    [addDigitalGiftWithCardToBasket, product, setError, storeId],
  )

  const addDigitalGiftToLastLineItem = useCallback(async () => {
    try {
      if (!basketId) {
        setError(true)
        return
      }

      await addDigitalGiftToBasket({
        productMasterVariant: {
          ...product.masterVariant,
          addons: product.masterVariant.addons || null,
        },
        lastLineItem,
        basketId,
        region: storeId,
      })

      router.push({ name: 'basket', params: { region: storeId } })
    } catch (e) {
      setError(true)
    }
  }, [
    addDigitalGiftToBasket,
    setError,
    basketId,
    lastLineItem,
    storeId,
    product,
    router,
  ])

  const showCardOptionsForDigitalGift = useCallback(() => {
    const gaEvent = createProductItemGAEvent({
      eventType: ProductItemEventEnum.SELECT_ITEM,
      product,
      variant: variant.title,
      index: product.productIndex || 0,
      listName: '',
      label: '',
    })
    seeCardOptionsForProduct({
      productId: product.id,
      gaEvent,
    })
  }, [product, seeCardOptionsForProduct, variant])

  const createGroupCard = () => {
    const isGroupCardNew = groupCardProjectId === 'new'
    const routeName = isGroupCardNew
      ? 'group-cards-create-project'
      : 'group-cards-dashboard'
    const parts = isGroupCardNew ? [] : [groupCardProjectId || '']
    const action = groupCardProjectId
      ? 'select group card'
      : 'create group card'
    const label = groupCardProjectId ? 'next' : 'make group card'
    const show = groupCardProjectId ? undefined : 'create-modal'

    selectContent({
      action,
      component: 'button',
      label,
    })

    router.push({
      name: routeName,
      params: {
        productId: product.id,
        productVariantSku: variant.sku || /* istanbul ignore next */ '',
        region: storeId,
        show,
        parts,
      },
    })
  }

  const personaliseProduct = () => {
    trackPersonaliseProduct()
    onAddToBasket({ buttonActionName: ButtonActionName.PERSONALISE })
  }

  const buttonActions = {
    onAddToBasket: () => {
      setUIHCookie({ product, facets: productContext?.filters })
      onAddToBasket({})
    },
    showCardOptionsForDigitalGift,
    addDigitalGiftCardToBasket: () => {
      if (digitalGift) {
        trackPersonaliseProduct()
        addDigitalGiftCardToBasket(digitalGift)
      }
    },
    addCrossSellGiftToBasket: () => {
      if (isDigitalGift(product)) {
        addDigitalGiftToLastLineItem()
        return
      }

      return product.customisable ? personaliseProduct() : onAddToBasket({})
    },
    addBasketCrossSellGiftToBasket: () => {
      return product.customisable
        ? personaliseProduct()
        : addBasketCrossSellGiftToBasket()
    },
    personalise: () => {
      setUIHCookie({ product, facets: productContext?.filters })
      personaliseProduct()
    },
    createGroupCard,
  }

  const buttons = createButtons({
    pageType,
    productType,
    product,
    groupCardProjectId,
    localiseProductInfoText,
    getCanSeeCardOptions,
    buttonActions,
    showSecondaryButton:
      requiresEditing?.value === false && product.customisable,
  })

  return {
    ...buttons,
    loading: addingToBasket || addingDigitalGiftToBasket,
    skipEditor:
      isCard && !groupCardProjectId && productType !== 'groupCard' && !isEcard,
  }
}
