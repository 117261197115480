import React, { FC, useEffect, useRef } from 'react'
import Head from 'next/head'
import type { Analytics, GoogleTagManagerClient } from '@moonpig/web-core-types'
import { useAnalytics } from '@moonpig/web-core-stores'

import { PageLoadEventFields } from './types'
import { useTrackScreenView } from './useTrackScreenView'
import { ManualEventObjectSchema } from './schemaTypes'

/* istanbul ignore next */
const useForceLocationRefresh = (analytics: Analytics) => {
  const isFirstRun = useRef(true)
  React.useEffect(() => {
    if (isFirstRun) {
      isFirstRun.current = false
    } else {
      // eslint-disable-next-line no-self-assign
      window.location = window.location
    }
  }, [analytics])
  return { isFirstRun }
}

const createScript = ({
  containerId,
  host,
}: {
  containerId: string
  host: string
}) => {
  return {
    gtmScript: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://${host}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${containerId}');
    `,
    noScriptIframeSource: `https://${host}/ns.html?id=${containerId}`,
  }
}

type PageTracking = {
  type: string
  disableDefaultPageTracking?: boolean
  sortby?: string
} & PageLoadEventFields

export const SetupGTM: FC<{
  pageTracking: PageTracking
  enableThirdPartyScripts?: boolean
}> = ({ pageTracking, enableThirdPartyScripts }) => {
  const analytics = useAnalytics()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      /* istanbul ignore next */
      window.validateEventPayload = (event: Record<string, unknown>) => {
        const validationResult = ManualEventObjectSchema.safeParse(event)

        if (validationResult.success) return true

        const errorTable = validationResult.error.issues.map(e => ({
          key: e.path.join('.'),
          error_type: e.code,
          criteria: e.message,
          // @ts-expect-error: we don't care if the property is there or not
          expected: e.expected || '',
          // @ts-expect-error: we don't care if the property is there or not
          received: e.received || '',
        }))

        // eslint-disable-next-line no-console
        console.table(errorTable, [
          'key',
          'error_type',
          'criteria',
          'expected',
          'received',
        ])

        return errorTable
      }

      /* istanbul ignore next */
      window.validateDataLayer = () => {
        window.dataLayer
          .filter(e => !!e.event)
          .forEach(e => {
            // eslint-disable-next-line no-console
            console.log(
              `Original Payload: \n${JSON.stringify(
                e,
                null,
                2,
              )} \n\nValidation Result:\n${JSON.stringify(
                window.validateEventPayload?.(e),
                null,
                2,
              )}`,
            )
          })
      }
    }

    return () => {
      window.validateEventPayload = undefined
      window.validateDataLayer = undefined
    }
  }, [])

  const settings: GoogleTagManagerClient = analytics.gtmClient

  useTrackScreenView(pageTracking)

  const { gtmScript, noScriptIframeSource } = createScript({
    containerId: settings.containerId,
    host: settings.host,
  })

  const { isFirstRun } = useForceLocationRefresh(analytics)

  // We only want to inject script if this is the components first ever mount
  // AND if we have a correct currentContainerId
  return isFirstRun.current &&
    settings.containerId &&
    enableThirdPartyScripts ? (
    <>
      <Head>
        {/* eslint-disable react/no-danger */}
        <script
          data-testid="gtm-script"
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || []; ${gtmScript}`,
          }}
        />
        {/* Google Webmaster Tools site verification code */}
        {'verificationCode' in settings ? (
          /* istanbul ignore next */ <meta
            name="google-site-verification"
            content={settings.verificationCode}
          />
        ) : null}
      </Head>

      <noscript>
        <iframe
          title="googleTagManager"
          src={noScriptIframeSource}
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  ) : null
}
