import React from 'react'
import { useLuxLabel, useLuxMeasure } from '@moonpig/web-core-utils'
import {
  ProductModalProvider,
  RecentlyViewedProvider,
  FavouritesProvider,
  PageTypeEnum,
} from '@moonpig/web-shared-products'
import { useFlags } from '@moonpig/web-explore-flags'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import { Home } from '../../components/Home'
import { PageContent, ContentModule } from '../../types'
import { useLuxAddExperimentData } from '../../utils'
import { DynamicHeroResponse } from '../../services/dataInference'

export type HomePageProps = {
  modules: ContentModule[]
  dynamicHeroData: DynamicHeroResponse
}

export const HomePage: PageContent<HomePageProps> = ({
  modules,
  dynamicHeroData,
}) => {
  const flags = useFlags()
  const clientSideRoutingEnabled = false
  const luxAbTestCustomData = [
    {
      name: 'clientSideRouting',
      value: clientSideRoutingEnabled.toString(),
    },
  ]
  const eppoPocTestVariant = useExperimentString('eppo-poc-test', {
    fallback: 'fallback',
  })

  useLuxLabel('Homepage Page')
  useLuxMeasure(clientSideRoutingEnabled)
  useLuxAddExperimentData(luxAbTestCustomData)

  return (
    <RecentlyViewedProvider>
      <FavouritesProvider
        location="Home"
        enableToastNotification={flags['enable-fav-toast-notification']}
      >
        <ProductModalProvider
          context={{ location: 'Home', pageType: PageTypeEnum.HOME, flags }}
        >
          <div data-eppo-poc-variant={eppoPocTestVariant} />
          <Home modules={modules} dynamicHeroData={dynamicHeroData} />
        </ProductModalProvider>
      </FavouritesProvider>
    </RecentlyViewedProvider>
  )
}

HomePage.getInitialProps = async ({ modules, dynamicHeroData }) => ({
  props: {
    modules,
    dynamicHeroData,
  },
  pageOptions: {
    tracking: {
      type: 'home',
    },
  },
})
