import { Locale, Store } from '@moonpig/web-core-types'
import {
  IconSystemAlcohol,
  IconSystemBirthday,
  IconSystemGift,
  IconSystemChocolate,
  IconSystemMug,
  IconSystemFlowers,
  IconSystemPlussparkle as IconSystemMoonpigPlus,
  IconSystemBalloons,
  IconSystemEgift as IconSystemEGifts,
  IconSystemValentinesday as IconSystemValentinesDay,
  IconSystemMothersday as IconSystemMothersDay,
  IconSystemFathersday as IconSystemFathersDay,
  IconSystemBrand,
  IconSystemCards,
  IconSystemChristmas,
  IconSystemGroup,
} from '@moonpig/launchpad-assets'
import { SVGProps } from 'react'
import {
  AppItem,
  CardFilterOption,
  FooterConfig,
  InterestFacetMapping,
  PaymentMethodsPerRegion,
  ProductTabType,
} from '../types'
import { createWebLoyaltyConfig } from '../shared'

const STORE_ID_UK = 'uk'
const STORE_ID_US = 'us'
const STORE_ID_AU = 'au'
const STORE_ID_IE = 'ie'

const LOCALE_EN_GB: Locale = {
  language: 'en-GB',
  countryCode: 'gb',
  currencyCode: 'gbp',
  timeZone: 'Europe/London',
}

const LOCALE_EN_US: Locale = {
  language: 'en-US',
  countryCode: 'us',
  currencyCode: 'usd',
  timeZone: 'America/New_York',
}

const LOCALE_EN_AU: Locale = {
  language: 'en-AU',
  countryCode: 'au',
  currencyCode: 'aud',
  timeZone: 'Australia/Sydney',
}

const LOCALE_EN_IE: Locale = {
  language: 'en-IE',
  countryCode: 'ie',
  currencyCode: 'eur',
  timeZone: 'Europe/Dublin',
}

const GTM_CLIENT = {
  containerId: 'GTM-M8RZDQ9',
  verificationCode: '6ynlpPuN0ok0-Oy8NELN7XktiFH-5iVAaoB7uIAdTUA',
  host: 'www.googletagmanager.com',
}

export const STORES: Store[] = [
  {
    id: STORE_ID_UK,
    legacyId: 'mnpg-uk',
    shortName: 'UK',
    name: 'United Kingdom',
    locale: LOCALE_EN_GB,
    analytics: {
      gtmClient: GTM_CLIENT,
    },
    webLoyalty: createWebLoyaltyConfig(STORE_ID_UK),
    supportsWork: true,
  },
  {
    id: STORE_ID_US,
    legacyId: 'mnpg-us',
    shortName: 'US & CA',
    name: 'United States & Canada',
    locale: LOCALE_EN_US,
    analytics: {
      gtmClient: GTM_CLIENT,
    },
    webLoyalty: createWebLoyaltyConfig(STORE_ID_US),
    supportsWork: false,
  },
  {
    id: STORE_ID_AU,
    legacyId: 'mnpg-au',
    shortName: 'AU & NZ',
    name: 'Australia & New Zealand',
    locale: LOCALE_EN_AU,
    analytics: {
      gtmClient: GTM_CLIENT,
    },
    webLoyalty: createWebLoyaltyConfig(STORE_ID_AU),
    supportsWork: false,
  },
  {
    id: STORE_ID_IE,
    legacyId: 'mnpg-ie',
    shortName: 'Ireland',
    name: 'Ireland',
    locale: LOCALE_EN_IE,
    analytics: {
      gtmClient: GTM_CLIENT,
    },
    webLoyalty: createWebLoyaltyConfig(STORE_ID_IE),
    supportsWork: false,
  },
]

const API_ENV = process.env.MNPG_ENVIRONMENT_NAME as string
const API_REGIONS = ['eu-west-1', 'us-east-1', 'ap-southeast-2']

const GOOGLE_AUTH_CLIENT_IDS: { [key: string]: string } = {
  local:
    '1039387418628-aeaadab0b9scdco8k0va17tq6fdpmt3e.apps.googleusercontent.com',
  dev: '207178689975-bggvued3vl0690un97015g3eer3raefv.apps.googleusercontent.com',
  uat: '1039387418628-aeaadab0b9scdco8k0va17tq6fdpmt3e.apps.googleusercontent.com',
  prod: '1088325004894-58r2stpbdfrm835inslp5f903vmvhq2l.apps.googleusercontent.com',
}

export const GOOGLE_AUTH_CLIENT_ID: string | undefined =
  GOOGLE_AUTH_CLIENT_IDS[API_ENV]

const createS3HostNames = (api: string, bucket: string) => {
  return API_REGIONS.map(
    region =>
      `mnpg-${api}-${region}-${API_ENV}-${bucket}.s3.${region}.amazonaws.com`,
  )
}

const MEDIA_API_HOST_NAMES = [
  // default region (without amazonaws.com subdomain)
  `mnpg-media-assets-us-east-1-${API_ENV}-uploaded.s3.amazonaws.com`,
  `mnpg-media-assets-us-east-1-${API_ENV}-converted.s3.amazonaws.com`,
  // other regions
  ...API_REGIONS.map(
    region =>
      `mnpg-media-assets-${region}-${API_ENV}-uploaded.s3.${region}.amazonaws.com`,
  ),
  ...API_REGIONS.map(
    region =>
      `mnpg-media-assets-${region}-${API_ENV}-converted.s3.${region}.amazonaws.com`,
  ),
]

const CUSTOMER_CREATIONS_API_HOST_NAMES = [
  // default region (without amazonaws.com subdomain)
  `mnpg-customer-creations-storage-us-east-1-${API_ENV}-assets.s3.amazonaws.com`,
  // other regions
  ...API_REGIONS.map(
    region =>
      `mnpg-customer-creations-storage-${region}-${API_ENV}-assets.s3.${region}.amazonaws.com`,
  ),
]

const DESIGN_API_HOST_NAMES = [
  // default region (without amazonaws.com subdomain)
  `mnpg-design-api-us-east-1-${API_ENV}-image-upload.s3.amazonaws.com`,
  // other regions
  ...API_REGIONS.flatMap(region => [
    `mnpg-design-api-${region}-${API_ENV}-image-upload.s3.${region}.amazonaws.com`,
    `mnpg-design-sticker-ai-generator-${region}-${API_ENV}-store.s3.${region}.amazonaws.com`,
  ]),
]

export const BUSINESS_API_HOST_NAMES = createS3HostNames(
  'business-api',
  'recipient-upload',
)

export const contentSecurityPolicy: { [key: string]: string[] } = {
  'font-src': [
    '*.moonpig.com',
    '*.moonpig.net',
    ...CUSTOMER_CREATIONS_API_HOST_NAMES,
  ],
  'connect-src': [
    ...DESIGN_API_HOST_NAMES,
    ...MEDIA_API_HOST_NAMES,
    ...BUSINESS_API_HOST_NAMES,
    ...CUSTOMER_CREATIONS_API_HOST_NAMES,
    '*.lunio.ai',
    '*.moonpig.com',
    '*.moonpig.net',
    '*.sail-horizon.com',
    '*.sail-personalize.com',
    '*.sail-track.com',
  ],
  'frame-src': [],
  'style-src': ['*.moonpig.com', '*.moonpig.net'],
  'script-src': [
    '*.moonpig.com',
    '*.moonpig.net',
    '*.sail-horizon.com',
    '*.sail-personalize.com',
    '*.sail-track.com',
  ],
  'img-src': [],
  'media-src': [
    ...MEDIA_API_HOST_NAMES,
    '*.moonpig.net',
    'videos.ctfassets.net',
  ],
}

export const ONE_TRUST_DOMAIN_SCRIPT_ID = 'e46f8143-8f58-4c40-a105-e42e91ce027b'

export const BRAND_LABEL = 'Moonpig'

export const DEPARTMENT_PATHS = {
  gifts: 'gifts',
  cards: 'cards',
  'flowers-and-plants': 'flowers-and-plants',
}

export const APP_STORE_ITEMS: AppItem[] = [
  {
    id: 'apple',
    icon: 'apple-app-store',
    to: 'https://itunes.apple.com/app/id393730279',
    width: '120px',
    height: '40px',
  },
  {
    id: 'google',
    icon: 'google-app-store',
    to: 'https://play.google.com/store/apps/details?id=com.commonagency.moonpig.uk',
    width: '135px',
    height: '40px',
  },
]

export const PAYMENT_METHODS: PaymentMethodsPerRegion = {
  uk: ['Visa', 'Mastercard', 'Google Pay', 'Apple Pay', 'PayPal', 'Klarna'],
  us: ['Visa', 'Mastercard', 'Google Pay', 'Apple Pay', 'PayPal'],
  au: ['Visa', 'Mastercard', 'Google Pay', 'Apple Pay', 'PayPal'],
  ie: ['Visa', 'Mastercard', 'Google Pay', 'Apple Pay', 'PayPal'],
}

export const FOOTER: FooterConfig = {
  socialItems: {
    uk: [
      {
        icon: 'facebook',
        linkHref: 'https://www.facebook.com/Moonpig/',
        label: 'Facebook',
      },
      {
        icon: 'twitter',
        linkHref: 'https://twitter.com/moonpiguk',
        label: 'Twitter',
      },
      {
        icon: 'instagram',
        linkHref: 'https://www.instagram.com/moonpig/',
        label: 'Instagram',
      },
      {
        icon: 'pinterest',
        linkHref: 'https://www.pinterest.co.uk/moonpiggroup/',
        label: 'Pinterest',
      },
      {
        icon: 'youtube',
        linkHref: 'https://www.youtube.com/user/moonpigcards',
        label: 'YouTube',
      },
    ],
    us: [
      {
        icon: 'facebook',
        linkHref: 'https://www.facebook.com/moonpigusa/',
        label: 'Facebook',
      },
      {
        icon: 'instagram',
        linkHref: 'https://www.instagram.com/moonpigusa/',
        label: 'Instagram',
      },
    ],
    au: [
      {
        icon: 'facebook',
        linkHref: 'https://www.facebook.com/MoonpigAustralia',
        label: 'Facebook',
      },
      {
        icon: 'instagram',
        linkHref: 'https://www.instagram.com/moonpigau/',
        label: 'Instagram',
      },
    ],
    ie: [
      {
        icon: 'facebook',
        linkHref: 'https://www.facebook.com/moonpigie',
        label: 'Facebook',
      },
      {
        icon: 'instagram',
        linkHref: 'https://www.instagram.com/moonpigie/',
        label: 'Instagram',
      },
    ],
  },
  appItems: APP_STORE_ITEMS,
  regionItems: [
    {
      region: 'uk',
      linkHref: '/uk/',
      shortLabel: 'UK',
      label: 'United Kingdom',
      icon: 'uk-flag',
    },
    {
      region: 'us',
      linkHref: '/us/',
      shortLabel: 'US',
      label: 'United States of America',
      icon: 'us-flag',
    },
    {
      region: 'au',
      linkHref: '/au/',
      shortLabel: 'AUS',
      label: 'Australia',
      icon: 'aus-flag',
    },
    {
      region: 'ie',
      linkHref: '/ie/',
      shortLabel: 'IE',
      label: 'Ireland',
      icon: 'ie-flag',
    },
  ],
  paymentProviders: PAYMENT_METHODS,
}

export const TRUSTPILOT_BUSINESS_UNIT_ID = '4bdecbe5000064000506bce0'

export const interestFacetsMappings: InterestFacetMapping[] = [
  {
    interestId: 'beer_fan',
    interestName: 'Beer Fan',
    facetKeys: ['beer-cider'],
    includeRecipient: false,
  },
  {
    interestId: 'wine_lover',
    interestName: 'Wine Lover',
    facetKeys: ['champagne-prosecco-wine'],
    includeRecipient: true,
  },
  {
    interestId: 'gin_and_spirits_fan',
    interestName: 'Gin and Spirits Fan',
    facetKeys: ['gin', 'vodka', 'other-spirits', 'whiskey'],
    includeRecipient: true,
  },
  {
    interestId: 'foodie',
    interestName: 'Foodie',
    facetKeys: [
      'snacks-treats-savoury',
      'gift-experiences-food-drink',
      'biscuits',
    ],
    priceKeys: [
      'price-£10orless',
      'price-£10-£20',
      'price-£20-£30',
      'price-£30-£40',
    ],
    includeRecipient: true,
  },
  {
    interestId: 'sweet_tooth',
    interestName: 'Sweet Tooth',
    facetKeys: ['chocolate', 'biscuits', 'sweets'],
    includeRecipient: true,
  },
  {
    interestId: 'wellness_enthusiast',
    interestName: 'Wellness Enthusiast',
    facetKeys: ['spa-breaks', 'beauty-face-body-gift-sets-letterbox'],
    includeRecipient: true,
  },
  {
    interestId: 'game_and_puzzle_solver',
    interestName: 'Game and Puzzle Solver',
    facetKeys: [
      'games-gadgets-novelty-games-board-games-puzzles',
      'gadgets',
      'games-gadgets-novelty-lego-sets',
      'games-gadgets-novelty-novelty',
    ],
    includeRecipient: true,
  },
  {
    interestId: 'jewellery_lover',
    interestName: 'Jewellery Lover',
    facetKeys: ['jewellery'],
    includeRecipient: false,
  },
  {
    interestId: 'home_stylist',
    interestName: 'Home Stylist',
    facetKeys: [
      'towels',
      'posters-prints',
      'photo-frames',
      'home-accessories',
      'glassware',
      'aprons',
    ],
    includeRecipient: true,
  },
  {
    interestId: 'gardener',
    interestName: 'Gardener',
    facetKeys: ['garden', 'grow-kits'],
    includeRecipient: true,
  },
  {
    interestId: 'adventurous',
    interestName: 'Adventurous',
    facetKeys: ['action-and-adventure', 'day-trips'],
    includeRecipient: true,
  },
  {
    interestId: 'holidayer',
    interestName: 'Holidayer',
    facetKeys: ['short-breaks', 'multi-choice'],
    includeRecipient: false,
  },
  {
    interestId: 'experience_fan',
    interestName: 'Experience Fan',
    facetKeys: [
      'product-type-gift-experiences',
      'product-type-digital-gift-experience',
    ],
    includeRecipient: true,
  },
  {
    interestId: 'flower_enthusiast',
    interestName: 'Flower Enthusiast',
    facetKeys: [
      'alstroemeria',
      'bouquets',
      'carnations',
      'dried-flowers',
      'germini',
      'lilies',
      'lisianthus',
      'roses',
      'stocks',
      'sunflowers',
      'tulips',
    ],
    includeRecipient: false,
  },
  {
    interestId: 'plant_lover',
    interestName: 'Plant Lover',
    facetKeys: ['indoor-plants', 'outdoor-plants'],
    includeRecipient: true,
  },
]

const CARD_FILTER_OCCASIONS: CardFilterOption[] = [
  {
    optionId: 'occasion-birthday',
    optionName: 'Birthday',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'anniversaries',
    optionName: 'Anniversary',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-thank-you',
    optionName: 'Thank You',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-thinking-of-you',
    optionName: 'Thinking of You',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-new-baby',
    optionName: 'New Baby',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-wedding',
    optionName: 'Wedding',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-new-home',
    optionName: 'New Home',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-sympathy',
    optionName: 'Sympathy',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-get-well',
    optionName: 'Get Well',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-congratulations',
    optionName: 'Congratulations',
    category: 'occasion',
    isSelected: false,
  },
  {
    optionId: 'occasion-leaving',
    optionName: 'Leaving',
    category: 'occasion',
    isSelected: false,
  },
]

const CARD_FILTER_RECIPIENTS: CardFilterOption[] = [
  {
    optionId: 'for-her',
    optionName: 'For Her',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'for-him',
    optionName: 'For Him',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-mum',
    optionName: 'Mum',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-dad',
    optionName: 'Dad',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'partner',
    optionName: 'Partner',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-wife',
    optionName: 'Wife',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-husband',
    optionName: 'Husband',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'grandma',
    optionName: 'Grandma',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-grandad',
    optionName: 'Grandad',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-daughter',
    optionName: 'Daughter',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-son',
    optionName: 'Son',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-sister',
    optionName: 'Sister',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'whos-it-for-brother',
    optionName: 'Brother',
    category: 'recipient',
    isSelected: false,
  },
  {
    optionId: 'friend',
    optionName: 'Friend',
    category: 'recipient',
    isSelected: false,
  },
]

const CARD_FILTER_SENTIMENT: CardFilterOption[] = [
  {
    optionId: 'sentiment-style-funny',
    optionName: 'Funny',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'cheeky',
    optionName: 'Cheeky',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'rude',
    optionName: 'Rude',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'sentiment-style-cute',
    optionName: 'Cute',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'sentiment-style-traditional',
    optionName: 'Traditional',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'sentiment-style-love',
    optionName: 'Love',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'artistic',
    optionName: 'Artistic',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'across-the-miles',
    optionName: 'Across the Miles',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'sentiment-style-floral',
    optionName: 'Floral',
    category: 'sentiment',
    isSelected: false,
  },
  {
    optionId: 'pun',
    optionName: 'Pun',
    category: 'sentiment',
    isSelected: false,
  },
]

export const CARD_FILTER_OPTIONS: CardFilterOption[] = [
  ...CARD_FILTER_OCCASIONS,
  ...CARD_FILTER_RECIPIENTS,
  ...CARD_FILTER_SENTIMENT,
]

export const GIFT_UPSELL_PRODUCT_IDS: {
  originalId: string
  upsellId: string
}[] = [
  { originalId: 'choc5503', upsellId: 'choc537' },
  { originalId: 'choc1267', upsellId: 'choc963' },
  { originalId: 'choc1256', upsellId: 'choc1261' },
  { originalId: 'choc1258', upsellId: 'choc1263' },
  { originalId: 'choc1259', upsellId: 'choc1264' },
  { originalId: 'choc1257', upsellId: 'choc1262' },
  { originalId: 'nwchlb4-ar', upsellId: 'alc301' },
  { originalId: 'bchmp17', upsellId: 'alc299' },
  { originalId: 'bchmp15', upsellId: 'alc297' },
  { originalId: 'bchmp16', upsellId: 'alc298' },
  { originalId: 'nwchlr5-ar', upsellId: 'alc500' },
  { originalId: 'swee903', upsellId: 'swee741' },
  { originalId: 'acc71', upsellId: 'acc71b' },
  { originalId: 'bisc01', upsellId: 'bisc05b' },
  { originalId: 'bisc30', upsellId: 'bisc05b' },
  { originalId: 'bisc21', upsellId: 'bisc05b' },
  { originalId: 'lbxg186', upsellId: 'hamp343' },
  { originalId: 'choc1358', upsellId: 'alc203b' },
  { originalId: 'beer418', upsellId: 'alc234b' },
  { originalId: 'ar420800', upsellId: 'ar422946' },
]

export const MULTI_CARD_LEARN_MORE_LINK = '/personalised-cards/multibuy/'

export const MULTI_CARD_CSV_LINK: { [key: string]: string } = {
  uk: 'https://static.web-purchase.prod.moonpig.net/_next/static/csv/9ecf61e5d4a2060090d8d2bffadd51ca/example_uk.csv',
  us: 'https://static.web-purchase.prod.moonpig.net/_next/static/csv/1e23fee7b578da983148c495af977d77/example_us.csv',
  au: 'https://static.web-purchase.prod.moonpig.net/_next/static/csv/744b7617711d57c86e21fea67ae58a77/example_au.csv',
  ie: 'https://static.web-purchase.prod.moonpig.net/_next/static/csv/c15a672490800cc1881ca2ddd8da9177/example_ie.csv',
}

export const MULTI_CARD_DISCOUNTS: {
  [key: string]: {
    label: string
    minimumQuantity: number
    discount: number
    code: string
  }[]
} = {
  uk: [],
  us: [],
  au: [],
  ie: [],
}

export const NAVIGATION_ICONS: {
  [key: string]: (props: SVGProps<SVGSVGElement>) => React.JSX.Element
} = {
  IconSystemChristmas,
  IconSystemBirthday,
  IconSystemCards,
  IconSystemFlowers,
  IconSystemGift,
  IconSystemChocolate,
  IconSystemMug,
  IconSystemMoonpigPlus,
  IconSystemBalloons,
  IconSystemEGifts,
  IconSystemValentinesDay,
  IconSystemMothersDay,
  IconSystemFathersDay,
  IconSystemBrand,
  IconSystemAlcohol,
  IconSystemGroup,
}

export const SHOW_PLUS_HEADER_RIGHT_SIDE_IMAGE = true

export const PRODUCT_TAB_TYPE: ProductTabType = 'GROUP_CARD'

export { CARD_VARIANT_IDS_ENABLED } from './product'

export const PERSISTED_FILTERS: { [key: string]: string } = {
  'not-rude': 'mnpg_exclude_rude_products',
}

export const SHOW_RUDE_TOGGLE = true
